// AoITab.js - Enhanced Component for Managing Areas of Interest (AOIs)

import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Snackbar,
  CircularProgress,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider,
  Paper,
  Avatar,
  Badge,
  List,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Save as SaveIcon,
  Close as CloseIcon,
  CloudUpload as CloudUploadIcon,
  CloudDownload as CloudDownloadIcon,
  Layers as LayersIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { MapContainer, TileLayer, GeoJSON, FeatureGroup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import * as turf from '@turf/turf';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import * as toGeoJSON from '@mapbox/togeojson';
import useAuthorizedHttp from '../../hooks/use-authorized-http'; // Custom hook for API calls

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  '&:hover': {
    boxShadow: theme.shadows[6],
    transition: 'box-shadow 0.3s',
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
  transition: 'background-color 0.3s, transform 0.3s',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    transform: 'scale(1.02)',
  },
}));

const projections = [
  { value: 'EPSG:4326', label: 'WGS 84 (EPSG:4326)' },
  { value: 'EPSG:3857', label: 'Web Mercator (EPSG:3857)' },
  // Add more projections as needed
];

const AoITab = () => {
  const [aois, setAois] = useState([]);
  const [filteredAois, setFilteredAois] = useState([]);
  const [aoiSearchQuery, setAoiSearchQuery] = useState('');
  const [currentAoi, setCurrentAoi] = useState(null);
  const [mapLayers, setMapLayers] = useState([]);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [newAoi, setNewAoi] = useState({
    name: '',
    description: '',
    projection: 'EPSG:4326',
    geojson: null,
  });
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [mapKey, setMapKey] = useState(0); // Used to reset map

  const makeRequest = useAuthorizedHttp(); // Custom hook for making API calls

  const mapRef = React.useRef();

  useEffect(() => {
    fetchAois();
  }, []);

  const fetchAois = async () => {
    setLoading(true);
    try {
      // Replace with actual API call
      /*
      const response = await makeRequest({
        url: '/aois/list',
        method: 'GET',
      });
      */
      // Simulated API data
      setTimeout(() => {
        const data = [
          {
            id: 1,
            name: 'Sample AOI 1',
            description: 'An area of interest in the city.',
            projection: 'EPSG:4326',
            geojson: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  properties: { name: 'Sample Polygon' },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-0.09, 51.505],
                        [-0.08, 51.51],
                        [-0.06, 51.52],
                        [-0.047, 51.51],
                        [-0.05, 51.5],
                        [-0.09, 51.505],
                      ],
                    ],
                  },
                },
              ],
            },
          },
          // Add more AOIs as needed
        ];
        setAois(data);
        setFilteredAois(data);
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.error('Failed to fetch AOIs', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    let filtered = [...aois];
    if (aoiSearchQuery.trim() !== '') {
      filtered = filtered.filter((aoi) =>
        aoi.name.toLowerCase().includes(aoiSearchQuery.toLowerCase())
      );
    }
    setFilteredAois(filtered);
  }, [aoiSearchQuery, aois]);

  const handleAddAoi = () => {
    setUploadDialogOpen(true);
    setCurrentAoi(null);
    setNewAoi({
      name: '',
      description: '',
      projection: 'EPSG:4326',
      geojson: null,
    });
  };

  const handleEditAoi = (aoi) => {
    setCurrentAoi(aoi);
    setUploadDialogOpen(true);
  };

  const handleDeleteAoi = async (aoiId) => {
    if (
      window.confirm(
        'Are you sure you want to delete this AOI? This action cannot be undone.'
      )
    ) {
      try {
        // Replace with actual API call
        /*
        await makeRequest({
          url: `/aois/${aoiId}`,
          method: 'DELETE',
        });
        */
        setAois((prevAois) => prevAois.filter((aoi) => aoi.id !== aoiId));
        setFilteredAois((prevAois) => prevAois.filter((aoi) => aoi.id !== aoiId));
        setSnackbarMessage('AOI deleted successfully.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Failed to delete AOI', error);
        setSnackbarMessage('Failed to delete AOI.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  const handleSaveAoi = async () => {
    try {
      if (currentAoi) {
        // Edit existing AOI
        // Replace with actual API call
        /*
        await makeRequest({
          url: `/aois/${currentAoi.id}`,
          method: 'PUT',
          data: currentAoi,
        });
        */
        setAois((prevAois) =>
          prevAois.map((aoi) => (aoi.id === currentAoi.id ? currentAoi : aoi))
        );
        setFilteredAois((prevAois) =>
          prevAois.map((aoi) => (aoi.id === currentAoi.id ? currentAoi : aoi))
        );
        setSnackbarMessage('AOI updated successfully.');
      } else {
        // Add new AOI
        // Replace with actual API call
        /*
        const response = await makeRequest({
          url: '/aois',
          method: 'POST',
          data: newAoi,
        });
        */
        const newId = aois.length + 1;
        const aoiToAdd = { id: newId, ...newAoi };
        setAois([...aois, aoiToAdd]);
        setFilteredAois([...aois, aoiToAdd]);
        setSnackbarMessage('AOI added successfully.');
      }
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setUploadDialogOpen(false);
      setCurrentAoi(null);
      setNewAoi({
        name: '',
        description: '',
        projection: 'EPSG:4326',
        geojson: null,
      });
    } catch (error) {
      console.error('Failed to save AOI', error);
      setSnackbarMessage('Failed to save AOI.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleMapClick = (e) => {
    // Handle map click events if needed
  };

  const onFeatureGroupReady = (reactFGref) => {
    // Store reference to the feature group
    if (reactFGref) {
      setMapLayers(reactFGref);
    }
  };

  const _onEdited = (e) => {
    let numEdited = 0;
    e.layers.eachLayer((layer) => {
      numEdited += 1;
    });
    console.log(`_onEdited: edited ${numEdited} layers`, e);
  };

  const _onCreated = (e) => {
    const { layerType, layer } = e;
    if (layerType === 'polygon') {
      // Do whatever you want with the polygon layer
      console.log('_onCreated: polygon created', layer);
    }
    // Add the new layer to the mapLayers
    if (mapLayers) {
      mapLayers.addLayer(layer);
    }
  };

  const _onDeleted = (e) => {
    let numDeleted = 0;
    e.layers.eachLayer((layer) => {
      numDeleted += 1;
    });
    console.log(`_onDeleted: removed ${numDeleted} layers`, e);
  };

  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      const extension = fileName.split('.').pop().toLowerCase();
      const reader = new FileReader();

      reader.onload = (event) => {
        let data;
        if (extension === 'geojson' || extension === 'json') {
          data = JSON.parse(event.target.result);
        } else if (extension === 'kml') {
          const kml = new DOMParser().parseFromString(
            event.target.result,
            'text/xml'
          );
          data = toGeoJSON.kml(kml);
        } else if (extension === 'gpx') {
          const gpx = new DOMParser().parseFromString(
            event.target.result,
            'text/xml'
          );
          data = toGeoJSON.gpx(gpx);
        } else {
          alert('Unsupported file format');
          return;
        }

        if (currentAoi) {
          setCurrentAoi({ ...currentAoi, geojson: data });
        } else {
          setNewAoi({ ...newAoi, geojson: data });
        }

        // Reset the map to display the new data
        setMapKey((prevKey) => prevKey + 1);
      };

      reader.readAsText(file);
    }
  };

  const handleAoiClick = (aoi) => {
    setCurrentAoi(aoi);
    // Zoom to AOI on the map
    if (mapRef.current) {
      const map = mapRef.current;
      const layer = L.geoJSON(aoi.geojson);
      map.fitBounds(layer.getBounds());
    }
  };

  const handleDownloadAoi = (aoi) => {
    const dataStr =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(aoi.geojson));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', `${aoi.name}.geojson`);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  return (
    <Box sx={{ width: '100%' }}>
      <StyledCard>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Areas of Interest (AOIs)
            <Badge
              badgeContent={aois.length}
              color="primary"
              sx={{ ml: 2 }}
            ></Badge>
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddAoi}
          >
            Add AOI
          </Button>
        </Grid>
        <Typography variant="body1" gutterBottom>
          Manage your project AOIs.
        </Typography>

        {/* Search Section */}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search AOIs..."
              value={aoiSearchQuery}
              onChange={(e) => setAoiSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <IconButton edge="start">
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
        </Grid>

        {/* Main Content */}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {/* AOIs List */}
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              {loading ? (
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                  <CircularProgress />
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    Loading AOIs...
                  </Typography>
                </Box>
              ) : (
                <List sx={{ mt: 2 }}>
                  {filteredAois.map((aoi) => (
                    <React.Fragment key={aoi.id}>
                      <StyledListItem
                        button
                        onClick={() => handleAoiClick(aoi)}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              backgroundColor: 'primary.main',
                              color: '#fff',
                            }}
                          >
                            <LayersIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={aoi.name}
                          secondary={aoi.description}
                        />
                        <Tooltip title="Download" arrow>
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDownloadAoi(aoi);
                            }}
                            sx={{
                              backgroundColor: '#e3f2fd',
                              '&:hover': {
                                backgroundColor: '#bbdefb',
                              },
                              marginRight: 1,
                            }}
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit" arrow>
                          <IconButton
                            color="secondary"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditAoi(aoi);
                            }}
                            sx={{
                              backgroundColor: '#f3e5f5',
                              '&:hover': {
                                backgroundColor: '#e1bee7',
                              },
                              marginRight: 1,
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" arrow>
                          <IconButton
                            color="error"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteAoi(aoi.id);
                            }}
                            sx={{
                              backgroundColor: '#ffebee',
                              '&:hover': {
                                backgroundColor: '#ffcdd2',
                              },
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </StyledListItem>
                    </React.Fragment>
                  ))}
                </List>
              )}
            </Paper>
          </Grid>

          {/* Map */}
          <Grid item xs={12} md={8}>
            <Paper elevation={3}>
              <MapContainer
                key={mapKey}
                center={[51.505, -0.09]}
                zoom={13}
                style={{ height: '500px', width: '100%' }}
                whenCreated={(mapInstance) => {
                  mapRef.current = mapInstance;
                }}
                onClick={handleMapClick}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">
                  OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {filteredAois.map((aoi, index) => (
                  <GeoJSON
                    key={index}
                    data={aoi.geojson}
                    style={() => ({
                      color: 'blue',
                      weight: 2,
                    })}
                    onEachFeature={(feature, layer) => {
                      layer.on({
                        click: () => {
                          handleAoiClick(aoi);
                        },
                      });
                    }}
                  />
                ))}
                {/* Editing Feature Group */}
                <FeatureGroup
                  ref={(reactFGref) => onFeatureGroupReady(reactFGref)}
                >
                  <EditControl
                    position="topright"
                    onEdited={_onEdited}
                    onCreated={_onCreated}
                    onDeleted={_onDeleted}
                    draw={{
                      rectangle: true,
                      circle: false,
                      circlemarker: false,
                      marker: false,
                      polyline: false,
                    }}
                  />
                  {/* Display current AOI's GeoJSON */}
                  {currentAoi && currentAoi.geojson && (
                    <GeoJSON data={currentAoi.geojson} />
                  )}
                  {/* Display new AOI's GeoJSON */}
                  {!currentAoi && newAoi.geojson && (
                    <GeoJSON data={newAoi.geojson} />
                  )}
                </FeatureGroup>
              </MapContainer>
            </Paper>
          </Grid>
        </Grid>
      </StyledCard>

      {/* Upload AOI Dialog */}
      <Dialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Slide}
      >
        <DialogTitle>{currentAoi ? 'Edit AOI' : 'Add AOI'}</DialogTitle>
        <DialogContent>
          <Box component="form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="AOI Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={currentAoi ? currentAoi.name : newAoi.name}
                  onChange={(e) =>
                    currentAoi
                      ? setCurrentAoi({
                          ...currentAoi,
                          name: e.target.value,
                        })
                      : setNewAoi({ ...newAoi, name: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  value={
                    currentAoi ? currentAoi.description : newAoi.description
                  }
                  onChange={(e) =>
                    currentAoi
                      ? setCurrentAoi({
                          ...currentAoi,
                          description: e.target.value,
                        })
                      : setNewAoi({ ...newAoi, description: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="projection-label">Projection</InputLabel>
                  <Select
                    labelId="projection-label"
                    label="Projection"
                    value={
                      currentAoi ? currentAoi.projection : newAoi.projection
                    }
                    onChange={(e) =>
                      currentAoi
                        ? setCurrentAoi({
                            ...currentAoi,
                            projection: e.target.value,
                          })
                        : setNewAoi({
                            ...newAoi,
                            projection: e.target.value,
                          })
                    }
                  >
                    {projections.map((option) => (
                      <MenuItem value={option.value} key={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  component="label"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Geo-data
                  <input
                    type="file"
                    hidden
                    accept=".geojson,.json,.kml,.gpx"
                    onChange={handleUploadFile}
                  />
                </Button>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Supported formats: GeoJSON, JSON, KML, GPX
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setUploadDialogOpen(false)}
            color="secondary"
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSaveAoi}
          >
            {currentAoi ? 'Save Changes' : 'Add AOI'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Feedback Messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbarOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        ContentProps={{
          sx: {
            backgroundColor: snackbarSeverity === 'success' ? 'green' : 'red',
          },
        }}
      />
    </Box>
  );
};

export default AoITab;
