import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Button,
  Grid,
  TextField,
  Modal,
  Typography,
  Snackbar,
  Tabs,
  Tab,
  AppBar,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Tooltip,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Stop as StopIcon,
  ContentCopy as ContentCopyIcon,
  Search as SearchIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import useAuthorizedHttp from '../../hooks/use-authorized-http';
import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';

const WorkflowForm = ({
  currentWorkflow,
  setCurrentWorkflow,
  handleSaveWorkflow,
  isEditingWorkflow,
  setWorkflowModalOpen,
}) => {
  const frequencyOptions = ['Daily', 'Weekly', 'Monthly', 'Yearly'];
  const notificationOptions = ['Email', 'SMS', 'Push Notification'];

  return (
    <Box component="form" onSubmit={handleSaveWorkflow}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Workflow Name"
            variant="outlined"
            fullWidth
            required
            value={currentWorkflow ? currentWorkflow.name : ''}
            onChange={(e) =>
              setCurrentWorkflow({
                ...currentWorkflow,
                name: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Data Input Connector"
            variant="outlined"
            fullWidth
            required
            value={currentWorkflow ? currentWorkflow.dataInput : ''}
            onChange={(e) =>
              setCurrentWorkflow({
                ...currentWorkflow,
                dataInput: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Data Output Connector"
            variant="outlined"
            fullWidth
            required
            value={currentWorkflow ? currentWorkflow.dataOutput : ''}
            onChange={(e) =>
              setCurrentWorkflow({
                ...currentWorkflow,
                dataOutput: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Model"
            variant="outlined"
            fullWidth
            required
            value={currentWorkflow ? currentWorkflow.model : ''}
            onChange={(e) =>
              setCurrentWorkflow({
                ...currentWorkflow,
                model: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="frequency-label">Frequency</InputLabel>
            <Select
              labelId="frequency-label"
              label="Frequency"
              value={currentWorkflow ? currentWorkflow.frequency : ''}
              onChange={(e) =>
                setCurrentWorkflow({
                  ...currentWorkflow,
                  frequency: e.target.value,
                })
              }
              required
            >
              {frequencyOptions.map((option) => (
                <MenuItem value={option} key={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="notification-label">Notification Method</InputLabel>
            <Select
              labelId="notification-label"
              label="Notification Method"
              value={currentWorkflow ? currentWorkflow.notification : ''}
              onChange={(e) =>
                setCurrentWorkflow({
                  ...currentWorkflow,
                  notification: e.target.value,
                })
              }
              required
            >
              {notificationOptions.map((option) => (
                <MenuItem value={option} key={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Cron Code"
            variant="outlined"
            fullWidth
            required
            value={currentWorkflow ? currentWorkflow.cronCode : ''}
            onChange={(e) =>
              setCurrentWorkflow({
                ...currentWorkflow,
                cronCode: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>
      {setWorkflowModalOpen ? (
        <DialogActions sx={{ mt: 2 }}>
          <Button
            onClick={() => setWorkflowModalOpen(false)}
            color="secondary"
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit" startIcon={<SaveIcon />}>
            {isEditingWorkflow ? 'Save Changes' : 'Add Workflow'}
          </Button>
        </DialogActions>
      ) : (
        <Box sx={{ mt: 3 }}>
          <Button variant="contained" color="primary" type="submit" startIcon={<SaveIcon />}>
            {isEditingWorkflow ? 'Save Changes' : 'Add Workflow'}
          </Button>
        </Box>
      )}
    </Box>
  );
};

const WorkflowTab = () => {
  const { projectId } = useParams();
  const [workflows, setWorkflows] = useState([]);
  const [filteredWorkflows, setFilteredWorkflows] = useState([]);
  const [workflowSearchQuery, setWorkflowSearchQuery] = useState('');
  const [workflowModalOpen, setWorkflowModalOpen] = useState(false);
  const [isEditingWorkflow, setIsEditingWorkflow] = useState(false);
  const [currentWorkflow, setCurrentWorkflow] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const makeRequest = useAuthorizedHttp();

  useEffect(() => {
    if (projectId) {
      setLoading(true);
      makeRequest(
        {
          url: `${process.env.REACT_APP_VORTX_URL}/workflow/list?project_id=${projectId}`,
          method: 'GET',
        },
        (data) => {
          setWorkflows(data);
          setFilteredWorkflows(data);
          setError(null);
          setLoading(false);
        },
        (error) => {
          setError('Failed to load workflows. Please try again.');
          setWorkflows([]);
          setFilteredWorkflows([]);
          setLoading(false);
        }
      );
    }
  }, [projectId, makeRequest]);

  useEffect(() => {
    let filtered = [...workflows];
    if (workflowSearchQuery.trim() !== '') {
      filtered = filtered.filter((workflow) =>
        workflow.name.toLowerCase().includes(workflowSearchQuery.toLowerCase())
      );
    }
    setFilteredWorkflows(filtered);
  }, [workflowSearchQuery, workflows]);

  const handleAddWorkflow = () => {
    setIsEditingWorkflow(false);
    setCurrentWorkflow({
      name: '',
      dataInput: '',
      dataOutput: '',
      model: '',
      frequency: '',
      notification: '',
      cronCode: '',
      status: 'New',
    });
    setWorkflowModalOpen(true);
  };

  const handleEditWorkflow = (workflow) => {
    setIsEditingWorkflow(true);
    setCurrentWorkflow(workflow);
    setWorkflowModalOpen(true);
  };

  const handleSaveWorkflow = async (e) => {
    e.preventDefault();

    if (isEditingWorkflow) {
      try {
        await makeRequest(
          {
            url: `${process.env.REACT_APP_VORTX_URL}/workflow/update/${currentWorkflow.id}`,
            method: 'PUT',
            data: currentWorkflow,
          },
          (data) => {
            setWorkflows((prevWorkflows) =>
              prevWorkflows.map((wf) => (wf.id === data.id ? data : wf))
            );
            setFilteredWorkflows((prevWorkflows) =>
              prevWorkflows.map((wf) => (wf.id === data.id ? data : wf))
            );
            setSuccessMessage('Workflow updated successfully.');
            setError(null);
          }
        );
      } catch (error) {
        setError('Failed to update workflow. Please try again.');
        setSuccessMessage(null);
      }
    } else {
      try {
        await makeRequest(
          {
            url: `${process.env.REACT_APP_VORTX_URL}/workflow/create`,
            method: 'POST',
            data: {
              ...currentWorkflow,
              projectId,
            },
          },
          (data) => {
            setWorkflows((prevWorkflows) => [...prevWorkflows, data]);
            setFilteredWorkflows((prevWorkflows) => [...prevWorkflows, data]);
            setSuccessMessage('Workflow created successfully.');
            setError(null);
          }
        );
      } catch (error) {
        setError('Failed to create workflow. Please try again.');
        setSuccessMessage(null);
      }
    }
    setWorkflowModalOpen(false);
  };

  const handleDeleteWorkflow = (workflowId) => {
    if (
      window.confirm(
        'Are you sure you want to delete this workflow? This action cannot be undone.'
      )
    ) {
      makeRequest(
        {
          url: `${process.env.REACT_APP_VORTX_URL}/workflow/delete/${workflowId}`,
          method: 'DELETE',
        },
        () => {
          setWorkflows((prevWorkflows) =>
            prevWorkflows.filter((wf) => wf.id !== workflowId)
          );
          setFilteredWorkflows((prevWorkflows) =>
            prevWorkflows.filter((wf) => wf.id !== workflowId)
          );
          setSuccessMessage('Workflow deleted successfully.');
          setError(null);
        },
        (error) => {
          setError('Failed to delete workflow. Please try again.');
          setSuccessMessage(null);
        }
      );
    }
  };

  const handleCancelWorkflow = (workflowId) => {
    if (window.confirm('Are you sure you want to stop this workflow?')) {
      makeRequest(
        {
          url: `${process.env.REACT_APP_VORTX_URL}/workflow/update/${workflowId}`,
          method: 'PUT',
          data: { status: 'Inactive' },
        },
        (updatedWorkflow) => {
          setWorkflows((prevWorkflows) =>
            prevWorkflows.map((wf) =>
              wf.id === updatedWorkflow.id ? updatedWorkflow : wf
            )
          );
          setFilteredWorkflows((prevWorkflows) =>
            prevWorkflows.map((wf) =>
              wf.id === updatedWorkflow.id ? updatedWorkflow : wf
            )
          );
          setSuccessMessage('Workflow stopped successfully.');
          setError(null);
        },
        (error) => {
          setError('Failed to stop workflow. Please try again.');
          setSuccessMessage(null);
        }
      );
    }
  };

  const handleCopyCronCode = (cronCode) => {
    navigator.clipboard.writeText(cronCode);
    setSuccessMessage('Cron code copied to clipboard!');
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [pageSize, setPageSize] = useState(5);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'dataInput',
      headerName: 'Data Input',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'dataOutput',
      headerName: 'Data Output',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'model',
      headerName: 'Model',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'frequency',
      headerName: 'Frequency',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'notification',
      headerName: 'Notification',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'cronCode',
      headerName: 'Cron Code',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <>
          {params.value}
          <Tooltip title="Copy Cron Code">
            <IconButton
              size="small"
              onClick={() => handleCopyCronCode(params.value)}
              sx={{ ml: 1 }}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <IconButton
              color="primary"
              size="small"
              onClick={() => handleEditWorkflow(params.row)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              color="error"
              size="small"
              onClick={() => handleDeleteWorkflow(params.row.id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Stop">
            <IconButton
              color="warning"
              size="small"
              onClick={() => handleCancelWorkflow(params.row.id)}
            >
              <StopIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const rows = filteredWorkflows.map((wf) => ({
    id: wf.id,
    ...wf,
  }));

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    if (error) {
      setSnackbarMessage(error);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (successMessage) {
      setSnackbarMessage(successMessage);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  }, [error, successMessage]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setError(null);
    setSuccessMessage(null);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Card sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Workflow
        </Typography>
        <Typography variant="body1">Manage your cron jobs here.</Typography>

        {/* Tabs */}
        <AppBar position="static" color="default" sx={{ mt: 2 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Workflows" />
            <Tab label="Add Workflow" />
            <Tab label="Best Practices" />
          </Tabs>
        </AppBar>

        {tabValue === 0 && (
          <Box sx={{ mt: 3 }}>
            {/* Search and Add Workflow Section */}
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Search workflows..."
                  value={workflowSearchQuery}
                  onChange={(e) => setWorkflowSearchQuery(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <IconButton edge="start">
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAddWorkflow}
                >
                  Add Workflow
                </Button>
              </Grid>
            </Grid>

            {/* Workflows DataGrid */}
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 25]}
                pagination
                loading={loading}
                sx={{
                  '& .MuiDataGrid-row:hover': {
                    backgroundColor: '#f5f5f5',
                  },
                }}
              />
            </div>
          </Box>
        )}

        {tabValue === 1 && (
          <Box sx={{ mt: 3 }}>
            {/* Add/Edit Workflow Form */}
            <Typography variant="h6" gutterBottom>
              {isEditingWorkflow ? 'Edit Workflow' : 'Add Workflow'}
            </Typography>
            <WorkflowForm
              currentWorkflow={currentWorkflow}
              setCurrentWorkflow={setCurrentWorkflow}
              handleSaveWorkflow={handleSaveWorkflow}
              isEditingWorkflow={isEditingWorkflow}
            />
          </Box>
        )}

        {tabValue === 2 && (
          <Box sx={{ mt: 3 }}>
            {/* Best Practices Content */}
            <Typography variant="h6">
              Best Practices to Schedule CRON Jobs
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              {/* Add best practices content here */}
              - Use descriptive names for your workflows.
              <br />
              - Test your cron expressions before deploying.
              <br />
              - Monitor the execution and handle failures gracefully.
              <br />
              - Keep your workflows modular and maintainable.
            </Typography>
          </Box>
        )}
      </Card>

      {/* Add/Edit Workflow Dialog */}
      <Dialog
        open={workflowModalOpen}
        onClose={() => setWorkflowModalOpen(false)}
        fullWidth
        maxWidth="md"
        TransitionComponent={Slide}
      >
        <DialogTitle>
          {isEditingWorkflow ? 'Edit Workflow' : 'Add Workflow'}
        </DialogTitle>
        <DialogContent>
          <WorkflowForm
            currentWorkflow={currentWorkflow}
            setCurrentWorkflow={setCurrentWorkflow}
            handleSaveWorkflow={handleSaveWorkflow}
            isEditingWorkflow={isEditingWorkflow}
            setWorkflowModalOpen={setWorkflowModalOpen}
          />
        </DialogContent>
      </Dialog>

      {/* Snackbar for feedback messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        ContentProps={{
          sx: {
            backgroundColor:
              snackbarSeverity === 'success' ? 'green' : 'red',
          },
        }}
      />
    </Box>
  );
};

export default WorkflowTab;
