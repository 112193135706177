// ArtifactsTab.js - Enhanced Component for Managing Artifacts

import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Button,
  Grid,
  TextField,
  Typography,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Snackbar,
  CircularProgress,
  Avatar,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider,
  Paper,
  Chip,
} from '@mui/material';
import {
  Add as AddIcon,
  Search as SearchIcon,
  CloudUpload as CloudUploadIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Description as DescriptionIcon,
  Image as ImageIcon,
  PictureAsPdf as PictureAsPdfIcon,
  InsertDriveFile as InsertDriveFileIcon,
  CloudDownload as CloudDownloadIcon,
  Close as CloseIcon,
  Save as SaveIcon,
  ImportExport as ImportExportIcon,
  InsertLink as InsertLinkIcon,
  FolderOpen as FolderOpenIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  Sort as SortIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import useAuthorizedHttp from '../../hooks/use-authorized-http'; // Custom hook for API calls

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  '&:hover': {
    boxShadow: theme.shadows[6],
    transition: 'box-shadow 0.3s',
  },
}));

const artifactTypes = [
  { value: 'image', label: 'Image', icon: <ImageIcon /> },
  { value: 'pdf', label: 'PDF', icon: <PictureAsPdfIcon /> },
  { value: 'document', label: 'Document', icon: <DescriptionIcon /> },
  { value: 'other', label: 'Other', icon: <InsertDriveFileIcon /> },
];

const ArtifactsTab = () => {
  const [artifacts, setArtifacts] = useState([]);
  const [filteredArtifacts, setFilteredArtifacts] = useState([]);
  const [artifactSearchQuery, setArtifactSearchQuery] = useState('');
  const [showViewDialog, setShowViewDialog] = useState(false);
  const [currentArtifact, setCurrentArtifact] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [newArtifact, setNewArtifact] = useState({
    name: '',
    type: '',
    size: '',
    uploadedAt: '',
    uploader: '',
    url: '',
    description: '',
  });
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [sortModel, setSortModel] = useState([
    { field: 'uploadedAt', sort: 'desc' },
  ]);

  const makeRequest = useAuthorizedHttp(); // Custom hook for making API calls

  useEffect(() => {
    fetchArtifacts();
  }, []);

  const fetchArtifacts = async () => {
    setLoading(true);
    try {
      // Replace with actual API call
      /*
      const response = await makeRequest({
        url: '/artifacts/list',
        method: 'GET',
      });
      */
      // Simulated API data
      setTimeout(() => {
        const data = [
          {
            id: 1,
            name: 'Project Plan',
            type: 'document',
            size: '1.2 MB',
            uploadedAt: '2023-10-07 10:00 AM',
            uploader: 'John Doe',
            url: 'https://example.com/project-plan.docx',
            description: 'Initial project plan document.',
          },
          {
            id: 2,
            name: 'Architecture Diagram',
            type: 'image',
            size: '500 KB',
            uploadedAt: '2023-10-06 09:30 AM',
            uploader: 'Jane Smith',
            url: 'https://example.com/architecture-diagram.png',
            description: 'System architecture diagram.',
          },
          {
            id: 3,
            name: 'Requirements Specification',
            type: 'pdf',
            size: '2.5 MB',
            uploadedAt: '2023-10-05 08:45 AM',
            uploader: 'Alice Johnson',
            url: 'https://example.com/requirements-spec.pdf',
            description: 'Detailed requirements specification.',
          },
        ];
        setArtifacts(data);
        setFilteredArtifacts(data);
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.error('Failed to fetch artifacts', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    let filtered = [...artifacts];
    if (artifactSearchQuery.trim() !== '') {
      filtered = filtered.filter((artifact) =>
        artifact.name.toLowerCase().includes(artifactSearchQuery.toLowerCase())
      );
    }
    setFilteredArtifacts(filtered);
  }, [artifactSearchQuery, artifacts]);

  const handleAddArtifact = () => {
    setUploadDialogOpen(true);
    setCurrentArtifact(null);
    setNewArtifact({
      name: '',
      type: '',
      size: '',
      uploadedAt: '',
      uploader: '',
      url: '',
      description: '',
    });
  };

  const handleEditArtifact = (artifact) => {
    setCurrentArtifact(artifact);
    setUploadDialogOpen(true);
  };

  const handleDeleteArtifact = async (artifactId) => {
    if (
      window.confirm(
        'Are you sure you want to delete this artifact? This action cannot be undone.'
      )
    ) {
      try {
        // Replace with actual API call
        /*
        await makeRequest({
          url: `/artifacts/${artifactId}`,
          method: 'DELETE',
        });
        */
        setArtifacts((prevArtifacts) =>
          prevArtifacts.filter((artifact) => artifact.id !== artifactId)
        );
        setFilteredArtifacts((prevArtifacts) =>
          prevArtifacts.filter((artifact) => artifact.id !== artifactId)
        );
        setSnackbarMessage('Artifact deleted successfully.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Failed to delete artifact', error);
        setSnackbarMessage('Failed to delete artifact.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  const handleViewArtifact = (artifact) => {
    setCurrentArtifact(artifact);
    setShowViewDialog(true);
  };

  const handleSaveArtifact = async () => {
    try {
      if (currentArtifact) {
        // Edit existing artifact
        // Replace with actual API call
        /*
        await makeRequest({
          url: `/artifacts/${currentArtifact.id}`,
          method: 'PUT',
          data: currentArtifact,
        });
        */
        setArtifacts((prevArtifacts) =>
          prevArtifacts.map((artifact) =>
            artifact.id === currentArtifact.id ? currentArtifact : artifact
          )
        );
        setFilteredArtifacts((prevArtifacts) =>
          prevArtifacts.map((artifact) =>
            artifact.id === currentArtifact.id ? currentArtifact : artifact
          )
        );
        setSnackbarMessage('Artifact updated successfully.');
      } else {
        // Add new artifact
        // Replace with actual API call
        /*
        const response = await makeRequest({
          url: '/artifacts',
          method: 'POST',
          data: newArtifact,
        });
        */
        const newId = artifacts.length + 1;
        const artifactToAdd = { id: newId, ...newArtifact };
        setArtifacts([...artifacts, artifactToAdd]);
        setFilteredArtifacts([...artifacts, artifactToAdd]);
        setSnackbarMessage('Artifact added successfully.');
      }
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setUploadDialogOpen(false);
      setCurrentArtifact(null);
      setNewArtifact({
        name: '',
        type: '',
        size: '',
        uploadedAt: '',
        uploader: '',
        url: '',
        description: '',
      });
    } catch (error) {
      console.error('Failed to save artifact', error);
      setSnackbarMessage('Failed to save artifact.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleImportArtifact = () => {
    setImportDialogOpen(true);
  };

  const handleSortChange = (field) => {
    const isAsc = sortModel[0]?.sort === 'asc';
    setSortModel([{ field, sort: isAsc ? 'desc' : 'asc' }]);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      minWidth: 200,
      sortable: true,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const type = artifactTypes.find((t) => t.value === params.value);
        return (
          <Box display="flex" alignItems="center">
            {type?.icon}
            <Typography sx={{ ml: 1 }}>{type?.label}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'size',
      headerName: 'Size',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'uploadedAt',
      headerName: 'Uploaded At',
      flex: 1.5,
      minWidth: 150,
      sortable: true,
    },
    {
      field: 'uploader',
      headerName: 'Uploader',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <>
          <Tooltip title="View">
            <IconButton
              color="primary"
              size="small"
              onClick={() => handleViewArtifact(params.row)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton
              color="secondary"
              size="small"
              onClick={() => handleEditArtifact(params.row)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              color="error"
              size="small"
              onClick={() => handleDeleteArtifact(params.row.id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <StyledCard>
        <Typography variant="h4" gutterBottom>
          Artifacts
        </Typography>
        <Typography variant="body1" gutterBottom>
          Manage your project artifacts.
        </Typography>

        {/* Search and Action Buttons */}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search artifacts..."
              value={artifactSearchQuery}
              onChange={(e) => setArtifactSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <IconButton edge="start">
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ImportExportIcon />}
              onClick={handleImportArtifact}
              sx={{ mr: 2 }}
            >
              Import Data
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddArtifact}
            >
              Add Artifact
            </Button>
          </Grid>
        </Grid>

        {/* Artifacts DataGrid */}
        {loading ? (
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <CircularProgress />
            <Typography variant="body1" sx={{ mt: 2 }}>
              Loading artifacts...
            </Typography>
          </Box>
        ) : (
          <div style={{ height: 500, width: '100%', marginTop: '16px' }}>
            <DataGrid
              rows={filteredArtifacts}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5, 10, 25]}
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
              sx={{
                '& .MuiDataGrid-row:hover': {
                  backgroundColor: '#f5f5f5',
                },
              }}
            />
          </div>
        )}
      </StyledCard>

      {/* View Artifact Dialog */}
      <Dialog
        open={showViewDialog}
        onClose={() => setShowViewDialog(false)}
        fullWidth
        maxWidth="md"
        TransitionComponent={Slide}
      >
        <DialogTitle>View Artifact</DialogTitle>
        <DialogContent>
          {currentArtifact && (
            <Box>
              <Typography variant="h5" gutterBottom>
                {currentArtifact.name}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <strong>Type:</strong> {currentArtifact.type.toUpperCase()}
                  </Typography>
                  <Typography>
                    <strong>Size:</strong> {currentArtifact.size}
                  </Typography>
                  <Typography>
                    <strong>Uploaded At:</strong> {currentArtifact.uploadedAt}
                  </Typography>
                  <Typography>
                    <strong>Uploaded By:</strong> {currentArtifact.uploader}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
                  {currentArtifact.type === 'image' ? (
                    <img
                      src={currentArtifact.url}
                      alt={currentArtifact.name}
                      style={{ maxWidth: '100%', maxHeight: '300px' }}
                    />
                  ) : (
                    <InsertDriveFileIcon sx={{ fontSize: 100 }} />
                  )}
                </Grid>
              </Grid>
              {currentArtifact.description && (
                <>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Description:
                  </Typography>
                  <Typography>{currentArtifact.description}</Typography>
                </>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowViewDialog(false)}
            color="secondary"
            startIcon={<CloseIcon />}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<CloudDownloadIcon />}
            href={currentArtifact?.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>

      {/* Upload Artifact Dialog */}
      <Dialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Slide}
      >
        <DialogTitle>
          {currentArtifact ? 'Edit Artifact' : 'Add Artifact'}
        </DialogTitle>
        <DialogContent>
          <Box component="form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Artifact Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={
                    currentArtifact ? currentArtifact.name : newArtifact.name
                  }
                  onChange={(e) =>
                    currentArtifact
                      ? setCurrentArtifact({
                          ...currentArtifact,
                          name: e.target.value,
                        })
                      : setNewArtifact({ ...newArtifact, name: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="artifact-type-label">Artifact Type</InputLabel>
                  <Select
                    labelId="artifact-type-label"
                    label="Artifact Type"
                    value={
                      currentArtifact ? currentArtifact.type : newArtifact.type
                    }
                    onChange={(e) =>
                      currentArtifact
                        ? setCurrentArtifact({
                            ...currentArtifact,
                            type: e.target.value,
                          })
                        : setNewArtifact({
                            ...newArtifact,
                            type: e.target.value,
                          })
                    }
                    required
                  >
                    {artifactTypes.map((option) => (
                      <MenuItem value={option.value} key={option.value}>
                        <Box display="flex" alignItems="center">
                          {option.icon}
                          <Typography sx={{ ml: 1 }}>
                            {option.label}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  value={
                    currentArtifact
                      ? currentArtifact.description
                      : newArtifact.description
                  }
                  onChange={(e) =>
                    currentArtifact
                      ? setCurrentArtifact({
                          ...currentArtifact,
                          description: e.target.value,
                        })
                      : setNewArtifact({
                          ...newArtifact,
                          description: e.target.value,
                        })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  component="label"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload File
                  <input
                    type="file"
                    hidden
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        const reader = new FileReader();
                        reader.onload = () => {
                          const uploadedAt = new Date().toLocaleString();
                          const uploader = 'Current User'; // Replace with actual user
                          const size = `${(file.size / 1024).toFixed(2)} KB`;
                          const url = reader.result;
                          if (currentArtifact) {
                            setCurrentArtifact({
                              ...currentArtifact,
                              size,
                              uploadedAt,
                              uploader,
                              url,
                            });
                          } else {
                            setNewArtifact({
                              ...newArtifact,
                              size,
                              uploadedAt,
                              uploader,
                              url,
                            });
                          }
                        };
                        reader.readAsDataURL(file);
                      }
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setUploadDialogOpen(false)}
            color="secondary"
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSaveArtifact}
          >
            {currentArtifact ? 'Save Changes' : 'Add Artifact'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Import Artifact Dialog */}
      <Dialog
        open={importDialogOpen}
        onClose={() => setImportDialogOpen(false)}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Slide}
      >
        <DialogTitle>Import Data from Connections</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Select a connection to import data from:
          </Typography>
          <List>
            <ListItem button>
              <ListItemAvatar>
                <Avatar>
                  <FolderOpenIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Database Connection" secondary="db.example.com" />
            </ListItem>
            <Divider />
            <ListItem button>
              <ListItemAvatar>
                <Avatar>
                  <CloudUploadIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Cloud Storage" secondary="AWS S3 Bucket" />
            </ListItem>
            <Divider />
            <ListItem button>
              <ListItemAvatar>
                <Avatar>
                  <InsertLinkIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="API Endpoint" secondary="https://api.example.com" />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setImportDialogOpen(false)}
            color="secondary"
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ImportExportIcon />}
            onClick={() => {
              setImportDialogOpen(false);
              setSnackbarMessage('Data imported successfully.');
              setSnackbarSeverity('success');
              setSnackbarOpen(true);
            }}
          >
            Import
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Feedback Messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbarOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        ContentProps={{
          sx: {
            backgroundColor: snackbarSeverity === 'success' ? 'green' : 'red',
          },
        }}
      />
    </Box>
  );
};

export default ArtifactsTab;
