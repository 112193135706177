import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';

import MainWrapper from '../../component/wrapper/MainWrapper';

import {
  Avatar,
  Box,
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
  Stack,
  Card,
  CardContent,
  Divider,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import {
  BusinessCenter as BusinessCenterIcon,
  DeleteOutline as DeleteOutlineIcon,
  CurrencyExchange as CurrencyExchangeIcon,
  Business as BusinessIcon,
  Home as HomeIcon,
  Language as LanguageIcon,
  AccountBalance as AccountBalanceIcon,
  Badge as BadgeIcon,
  Public as PublicIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

// Static data for options (to be replaced with actual API calls)
const countryList = ['India', 'USA', 'UK', 'Australia', 'Canada'];

const currencyList = [
  { value: 'USD', label: 'USD - US Dollar' },
  { value: 'EUR', label: 'EUR - Euro' },
  { value: 'GBP', label: 'GBP - British Pound' },
  { value: 'INR', label: 'INR - Indian Rupee' },
  { value: 'JPY', label: 'JPY - Japanese Yen' },
  { value: 'AUD', label: 'AUD - Australian Dollar' },
];

const taxIdOptionsData = {
  India: [
    { value: 'GST', label: 'GST - Goods and Services Tax' },
    { value: 'CIN', label: 'CIN - Corporate Identification Number' },
  ],
  USA: [
    { value: 'EIN', label: 'EIN - Employer Identification Number' },
    { value: 'TIN', label: 'TIN - Taxpayer Identification Number' },
  ],
  Default: [{ value: 'TAX', label: 'Tax ID' }],
};

const UserProfile = () => {
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    businessDetails: '',
    businessName: '',
    businessAddress: '',
    currency: '',
    taxIdName: '',
    taxIdValue: '',
    website: '',
    countryOfResidence: '',
    countryOfBilling: '',
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteConfirmationText, setDeleteConfirmationText] = useState('');
  const [countryOptions, setCountryOptions] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [taxIdOptions, setTaxIdOptions] = useState([]);

  useEffect(() => {
    fetchUserData();
    fetchCountryOptions();
    fetchCurrencyOptions();
  }, []);

  useEffect(() => {
    fetchTaxIdOptions(userData.countryOfBilling);
  }, [userData.countryOfBilling]);

  const fetchUserData = async () => {
    // Simulated API call
    const response = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          firstName: 'Avijeet',
          lastName: 'Singh',
          username: 'avijeet.singh',
          email: 'avijeet@vortx.ai',
          businessDetails: 'Your Business Details Here',
          businessName: '',
          businessAddress: '',
          currency: 'USD',
          taxIdName: '',
          taxIdValue: '',
          website: '',
          countryOfResidence: 'India',
          countryOfBilling: 'India',
        });
      }, 1000);
    });
    setUserData(response);
  };

  const fetchCountryOptions = async () => {
    // Simulated API call
    const response = await new Promise((resolve) => {
      setTimeout(() => {
        resolve(countryList);
      }, 500);
    });
    setCountryOptions(response);
  };

  const fetchCurrencyOptions = async () => {
    // Simulated API call
    const response = await new Promise((resolve) => {
      setTimeout(() => {
        resolve(currencyList);
      }, 500);
    });
    setCurrencyOptions(response);
  };

  const fetchTaxIdOptions = async (country) => {
    // Simulated API call based on country
    const response = await new Promise((resolve) => {
      setTimeout(() => {
        resolve(taxIdOptionsData[country] || taxIdOptionsData['Default']);
      }, 500);
    });
    setTaxIdOptions(response);
  };

  const handleInputChange = (prop) => (event) => {
    setUserData({ ...userData, [prop]: event.target.value });
  };

  const handleDeleteAccount = () => {
    // Logic to handle account deletion
    setShowDeleteModal(false);
    setDeleteConfirmationText('');
  };

  return (
    <MainWrapper>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Card elevation={3} sx={{ p: 3, m: 'auto', maxWidth: 900, borderRadius: 3 }}>
          <CardContent>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              sx={{ textAlign: 'center', fontWeight: 'bold' }}
            >
              Profile & Business details
            </Typography>
            <Divider sx={{ mb: 3 }} />
            <Stack spacing={3} alignItems="center">
              <Avatar src="https://via.placeholder.com/150" sx={{ width: 120, height: 120 }} />
              <Button variant="contained" color="primary">
                Change Photo
              </Button>
            </Stack>
            <Box sx={{ mt: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="First Name"
                    fullWidth
                    variant="outlined"
                    value={userData.firstName}
                    onChange={handleInputChange('firstName')}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Last Name"
                    fullWidth
                    variant="outlined"
                    value={userData.lastName}
                    onChange={handleInputChange('lastName')}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Username"
                    fullWidth
                    variant="outlined"
                    value={userData.username}
                    onChange={handleInputChange('username')}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Email"
                    fullWidth
                    variant="outlined"
                    value={userData.email}
                    disabled
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={countryOptions}
                    value={userData.countryOfResidence}
                    onChange={(event, newValue) =>
                      setUserData({ ...userData, countryOfResidence: newValue })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country of Residence"
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: <PublicIcon sx={{ mr: 1 }} />,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={countryOptions}
                    value={userData.countryOfBilling}
                    onChange={(event, newValue) =>
                      setUserData({ ...userData, countryOfBilling: newValue })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country of Billing"
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: <PublicIcon sx={{ mr: 1 }} />,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Accordion sx={{ mt: 3 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="business-details-content"
                  id="business-details-header"
                >
                  <Typography variant="h6">Business Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Business Name"
                        fullWidth
                        variant="outlined"
                        value={userData.businessName}
                        onChange={handleInputChange('businessName')}
                        margin="normal"
                        InputProps={{
                          startAdornment: <BusinessIcon sx={{ mr: 1 }} />,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Business Address"
                        fullWidth
                        variant="outlined"
                        value={userData.businessAddress}
                        onChange={handleInputChange('businessAddress')}
                        margin="normal"
                        InputProps={{
                          startAdornment: <HomeIcon sx={{ mr: 1 }} />,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Website"
                        fullWidth
                        variant="outlined"
                        value={userData.website}
                        onChange={handleInputChange('website')}
                        margin="normal"
                        InputProps={{
                          startAdornment: <LanguageIcon sx={{ mr: 1 }} />,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        options={taxIdOptions}
                        getOptionLabel={(option) => option.label}
                        value={
                          taxIdOptions.find((option) => option.value === userData.taxIdName) || null
                        }
                        onChange={(event, newValue) =>
                          setUserData({ ...userData, taxIdName: newValue ? newValue.value : '' })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tax ID Name"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: <BadgeIcon sx={{ mr: 1 }} />,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Tax ID Value"
                        fullWidth
                        variant="outlined"
                        value={userData.taxIdValue}
                        onChange={handleInputChange('taxIdValue')}
                        margin="normal"
                        InputProps={{
                          startAdornment: <AccountBalanceIcon sx={{ mr: 1 }} />,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Business Details"
                        fullWidth
                        multiline
                        maxRows={4}
                        value={userData.businessDetails}
                        onChange={handleInputChange('businessDetails')}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        options={currencyOptions}
                        getOptionLabel={(option) => option.label}
                        value={
                          currencyOptions.find((option) => option.value === userData.currency) ||
                          null
                        }
                        onChange={(event, newValue) =>
                          setUserData({ ...userData, currency: newValue ? newValue.value : '' })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Currency"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: <CurrencyExchangeIcon sx={{ mr: 1 }} />,
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
              <Button
                startIcon={<DeleteOutlineIcon />}
                color="error"
                variant="outlined"
                onClick={() => setShowDeleteModal(true)}
              >
                Delete Account
              </Button>
              <Button startIcon={<BusinessCenterIcon />} variant="contained" color="primary">
                Update Details
              </Button>
            </Box>
          </CardContent>
        </Card>

        <Modal
          open={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
            setDeleteConfirmationText('');
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 3,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Confirm Account Deletion
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete your account? This action is irreversible.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Please type <strong>DELETE</strong> to confirm:
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              value={deleteConfirmationText}
              onChange={(e) => setDeleteConfirmationText(e.target.value)}
            />
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={() => {
                  setShowDeleteModal(false);
                  setDeleteConfirmationText('');
                }}
                sx={{ mr: 2 }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={handleDeleteAccount}
                color="error"
                variant="contained"
                disabled={deleteConfirmationText !== 'DELETE'}
                sx={{
                  bgcolor: deleteConfirmationText === 'DELETE' ? 'error.main' : 'grey.400',
                  '&:hover': {
                    bgcolor: deleteConfirmationText === 'DELETE' ? 'error.dark' : 'grey.400',
                  },
                }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </MainWrapper>
  );
};

export default UserProfile;
