// NavigationalPlayground.jsx
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import 'leaflet-draw'; // Imports leaflet-draw and attaches to L
import 'leaflet-draw/dist/leaflet.draw.css';

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  IconButton,
  Tooltip,
  Autocomplete,
  TextField,
  CircularProgress,
  Alert,
  Modal,
  Backdrop,
  Fade,
  Paper,
  Tabs,
  Tab,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
  Snackbar,
  Stack,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import {
  CloudUpload as CloudUploadIcon,
  Map as MapIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Image as ImageIcon,
  Search as SearchIcon,
  Layers as LayersIcon,
  Gesture as GestureIcon,
  PlayArrow as PlayArrowIcon,
  History as HistoryIcon,
  Tag as TagIcon,
  VideoLibrary as VideoLibraryIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  Download as DownloadIcon,
  Visibility as VisibilityIcon,
  DragIndicator as DragIndicatorIcon,
  Close as CloseIcon,
  AddCircle as AddCircleIcon,
} from '@mui/icons-material';
import AddDataModal from "../../component/pojectsComponents/addDataModal/AddDataModal";
import { ProtocolList } from '../../pages/cryptobasket/ProtocolList';
import MainWrapper from '../wrapper/MainWrapper';
import { MapContainer, TileLayer, FeatureGroup, useMap, Marker, Popup } from 'react-leaflet';
import { EditControl } from "react-leaflet-draw";
import 'leaflet/dist/leaflet.css';
import "leaflet-draw/dist/leaflet.draw.css";
import './NavigationalPlayground.css';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import InputAdornment from '@mui/material/InputAdornment';
import HttpsIcon from '@mui/icons-material/Https';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(10),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  minHeight: '450px',
  borderRadius: '4px',
  boxShadow: theme.shadows[2],
  position: 'relative',
  padding: theme.spacing(0.5),
}));

const HoverButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2.8),
  marginBottom: theme.spacing(0.8),
  width: '100%',
  transition: 'all 0.1s ease',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transform: 'none',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '80%',
  maxHeight: '80vh',
  overflowY: 'auto',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2, 4, 3),
  bottom: '10%',
  left: '10%',
  borderRadius: '16px',
}));

const DraggableItem = styled('div')(({ theme }) => ({
  display: 'inline-block',
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  background: `linear-gradient(135deg, ${theme.palette.grey[200]} 0%, ${theme.palette.grey[300]} 100%)`,
  borderRadius: theme.shape.borderRadius,
  cursor: 'grab',
  userSelect: 'none',
  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: theme.palette.grey[400],
  },
  '&:active': {
    cursor: 'grabbing',
    transform: 'scale(0.95)',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
  },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  background: 'linear-gradient(135deg, rgba(245, 250, 250, 0.7) 0%, rgba(250, 249, 246, 0.7) 10%)',
  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius * 1,
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.005)',
    boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.2)',
  },
}));

const NavigationalPlayground = () => {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { model: modelFromParams } = useParams();
  const navigate = useNavigate();

  const [model, setModel] = useState(modelFromParams || '');
  const [selectedModel, setSelectedModel] = useState(null);
  const [dataType, setDataType] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [promptText, setPromptText] = useState('');
  const [apiResponse, setApiResponse] = useState(null);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [hasResults, setHasResults] = useState(false);
  const [openAddDataModal, setOpenAddDataModal] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [openMapModal, setOpenMapModal] = useState(false);
  const [drawnItems, setDrawnItems] = useState([]);
  const [stacFiles, setStacFiles] = useState([]);
  const [locationQuery, setLocationQuery] = useState('');
  const [mapLocationQuery, setMapLocationQuery] = useState('');
  const [outputTab, setOutputTab] = useState(0);
  const [savedTags, setSavedTags] = useState([]);
  const [history, setHistory] = useState([]);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [openTagsModal, setOpenTagsModal] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [mapCenter, setMapCenter] = useState([51.505, -0.09]);
  const [mapMarkers, setMapMarkers] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [formData, setFormData] = useState({
    cameraip: '',
    accessport: '',
    // ...add any other form fields here
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const closeModal = () => {
    console.log('Modal closed');
  };

  const addCamera = () => {
    if (!formData.cameraip || !formData.accessport) {
      alert('Please fill in all required fields.');
      return;
    }

    console.log('Saving camera data:', formData);

    // Example save logic (API call):
    axios
      .post('/api/cameras', formData)
      .then((response) => {
        console.log('Camera data saved:', response.data);
        // Close the modal after successful save
        closeModal();
      })
      .catch((error) => {
        console.error('Error saving camera data:', error);
      });

    console.log('Camera added successfully');

    // Close modal after saving
    closeModal();
  };

  useEffect(() => {
    const foundModel = ProtocolList.find((protocol) => protocol.model === model);
    setSelectedModel(foundModel);
  }, [model]);

  useEffect(() => {
    if (selectedModel && selectedModel.dataTypes && selectedModel.dataTypes.length > 0) {
      setDataType(selectedModel.dataTypes[0]);
    } else {
      setDataType('');
    }
  }, [selectedModel]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setSelectedItems([...selectedItems, { type: 'image', data: file }]);
    }
  };

  const handleSTACFileUpload = (e) => {
    const files = Array.from(e.target.files);
    setStacFiles(files);
    const stacItems = files.map((file) => ({ type: 'stac', data: file }));
    setSelectedItems([...selectedItems, ...stacItems]);
  };

  const handleGenerate = async () => {
    if (selectedItems.length === 0 && !promptText.trim()) {
      alert('Please upload data or enter a prompt.');
      return;
    }

    setLoader(true);
    setErrorMessage(null);

    const formData = new FormData();
    if (imageFile) {
      formData.append('image', imageFile, imageFile.name);
    }
    if (promptText.trim()) {
      formData.append('prompt', promptText.trim());
      setSavedTags([...savedTags, promptText.trim()]);
    }
    if (stacFiles.length > 0) {
      stacFiles.forEach((file, index) => {
        formData.append(`stacFile${index}`, file);
      });
    }

    if (drawnItems.length > 0) {
      const geoJsonData = drawnItems.map((layer) => layer.toGeoJSON());
      formData.append('geojson', JSON.stringify(geoJsonData));
    }

    try {
      // Construct the API URL with the Secret-Key as a query parameter
      const apiUrl = `${process.env.REACT_APP_FOOD_API}?Secret-Key=${process.env.REACT_APP_FOOD_KEY}`;
      //const apiUrl = 'http://35.222.103.45/models/pipeline/?Secret-Key=90a72752-4003-4eef-8624-da30105652fa';
      const response = await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('API Response:', response);

      if (response.data.status === 200 && response.data.message === 'Success') {
        setHasResults(true);
        setApiResponse(response.data);
        setHistory([...history, response.data]);
        setSnackbarMessage('Generation successful!');
        setSnackbarOpen(true);
      } else {
        setErrorMessage(response.data.message || 'Failed to generate image.');
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(`Error: ${error.response.data.message || 'Failed to generate image.'}`);
      } else if (error.request) {
        setErrorMessage('No response from the server. Please try again later.');
      } else {
        setErrorMessage(`Error: ${error.message}`);
      }
    } finally {
      setLoader(false);
    }
  };

  const handleScheduleProject = () => {
    navigate('/projects');
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleOutputTabChange = (event, newValue) => {
    setOutputTab(newValue);
  };

  const handleMapDraw = (e) => {
    const layer = e.layer;
    setDrawnItems([...drawnItems, layer]);
    setSelectedItems([...selectedItems, { type: 'shape', data: layer }]);
    setSnackbarMessage('Shape added to selection.');
    setSnackbarOpen(true);
  };

  const openMapDialog = () => {
    setOpenMapModal(true);
  };

  const closeMapDialog = () => {
    setOpenMapModal(false);
  };

  const openHistoryDialog = () => {
    setOpenHistoryModal(true);
  };

  const closeHistoryDialog = () => {
    setOpenHistoryModal(false);
  };

  const openTagsDialog = () => {
    setOpenTagsModal(true);
  };

  const closeTagsDialog = () => {
    setOpenTagsModal(false);
  };

  const openVideoDialog = () => {
    setOpenVideoModal(true);
  };

  const closeVideoDialog = () => {
    setOpenVideoModal(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDragStart = (e, item) => {
    e.dataTransfer.setData('item', JSON.stringify(item));
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const item = JSON.parse(e.dataTransfer.getData('item'));
    if (item.type === 'image' || item.type === 'stac') {
      setPromptText((prev) => `${prev} [${item.data.name}]`);
    } else if (item.type === 'shape') {
      setPromptText((prev) => `${prev} [Drawn Shape]`);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const leftGridSize = isSmDown ? 12 : 6.5;
  const rightGridSize = isSmDown ? 12 : 5.5;

  const speedDialActions = [
    { icon: <HistoryIcon />, name: 'History', action: openHistoryDialog },
    { icon: <TagIcon />, name: 'Tags', action: openTagsDialog },
    { icon: <VideoLibraryIcon />, name: 'Usage Video', action: openVideoDialog },
  ];

  return (
    <MainWrapper>
      <StyledAppBar position="static" elevation={0}>
        <Toolbar>
          <BoxContainer>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {selectedModel?.title || 'Vortx Synthesis Studio'}
            </Typography>
            <Autocomplete
              options={selectedModel?.dataTypes || []}
              value={dataType}
              onChange={(event, newValue) => setDataType(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Data Type" variant="outlined" />
              )}
              sx={{ width: '200px', marginRight: 2 }}
            />
            <Autocomplete
              options={ProtocolList.map((protocol) => protocol.model)}
              getOptionLabel={(option) => {
                const protocol = ProtocolList.find((p) => p.model === option);
                return protocol ? protocol.name : option;
              }}
              value={model}
              onChange={(event, newValue) => setModel(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Model" variant="outlined" />
              )}
              sx={{ width: '200px', marginRight: 2 }}
            />
          </BoxContainer>
        </Toolbar>
      </StyledAppBar>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      />

      <StyledContainer>
        <Grid container spacing={3}>
          <Grid item xs={12} md={leftGridSize}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6">Input</Typography>

                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab label="STAC" icon={<MapIcon />} />
                  <Tab label="Images" icon={<CloudUploadIcon />} />
                  <Tab label="Vortx ID" icon={<SearchIcon />} />
                </Tabs>

                <TabPanel value={tabValue} index={0}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <label htmlFor="map-add">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          fullWidth
                          startIcon={<GestureIcon />}
                          sx={{
                            backgroundColor: '#FFFFFF',
                            color: '#000000',
                            borderRadius: 1,
                            fontSize: 12,
                            fontWeight: 100,
                            textTransform: 'capitalize',
                            '&:hover': {
                              backgroundColor: '#0c0678',
                              color: '#FFFFFF',
                            },
                          }}
                          onClick={openMapDialog}
                          className="animated-button"
                        >
                          Draw on Map
                        </Button>
                      </label>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <input
                        accept=".csv,.kml,.tif,.geojson,.json"
                        style={{ display: 'none' }}
                        id="stac-file-upload"
                        multiple
                        type="file"
                        onChange={handleSTACFileUpload}
                      />
                      <label htmlFor="stac-file-upload">
                        <Button
                          variant="contained"
                          color="secondary"
                          component="span"
                          fullWidth
                          startIcon={<InsertDriveFileIcon />}
                          sx={{
                            backgroundColor: '#FFFFFF',
                            color: '#000000',
                            borderRadius: 1,
                            fontSize: 12,
                            fontWeight: 100,
                            textTransform: 'capitalize',
                            '&:hover': {
                              backgroundColor: '#0c0678',
                              color: '#FFFFFF',
                            },
                          }}
                          className="animated-button"
                        >
                          Upload STAC Files
                        </Button>
                      </label>
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <label htmlFor="add-imaging-device">
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<GestureIcon />}
                          sx={{
                            backgroundColor: '#FFFFFF',
                            color: '#000000',
                            borderRadius: 1,
                            fontSize: 12,
                            fontWeight: 100,
                            textTransform: 'capitalize',
                            '&:hover': {
                              backgroundColor: '#0c0678',
                              color: '#FFFFFF',
                            },
                          }}
                          onClick={openVideoDialog}
                          fullWidth
                          className="animated-button"
                        >
                          Connect Imaging Device
                        </Button>
                      </label>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={handleImageChange}
                      />
                      <label htmlFor="raised-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          fullWidth
                          startIcon={<ImageIcon />}
                          sx={{
                            backgroundColor: '#FFFFFF',
                            color: '#000000',
                            borderRadius: 1,
                            fontSize: 12,
                            fontWeight: 100,
                            textTransform: 'capitalize',
                            '&:hover': {
                              backgroundColor: '#0c0678',
                              color: '#FFFFFF',
                            },
                          }}
                          className="animated-button"
                        >
                          Upload Image
                        </Button>
                      </label>
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={tabValue} index={2}>
                  <TextField
                    label="Search Vortx Location ID"
                    variant="outlined"
                    fullWidth
                    value={locationQuery}
                    onChange={(e) => setLocationQuery(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={() => setMapLocationQuery(locationQuery)}>
                          <SearchIcon />
                        </IconButton>
                      ),
                    }}
                  />
                  {searchResults.length > 0 && (
                    <List>
                      {searchResults.map((result, index) => (
                        <ListItem
                          key={index}
                          button
                          onClick={() => {
                            setMapCenter([result.lat, result.lon]);
                            setMapMarkers([
                              ...mapMarkers,
                              { position: [result.lat, result.lon], popup: result.display_name },
                            ]);
                            setSelectedItems([...selectedItems, { type: 'location', data: result }]);
                            setSnackbarMessage('Location added to selection.');
                            setSnackbarOpen(true);
                          }}
                        >
                          <ListItemText primary={result.display_name} />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </TabPanel>

                <TextField
                  label="Enhance by Prompt"
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  sx={{ mt: 2 }}
                  value={promptText}
                  onChange={(e) => setPromptText(e.target.value)}
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                />
                <Box sx={{ marginBottom: 6, marginTop: 2 }}>
                  {selectedItems.length > 0 && (
                    <Typography
                      variant="h6"
                      fontSize={12}
                      fontWeight={100}
                      color="#808080"
                    >
                      Total uploads: {selectedItems.length}
                    </Typography>
                  )}
                  <Box>
                    {selectedItems.map((item, index) => (
                      <DraggableItem
                        key={index}
                        draggable
                        onDragStart={(e) =>
                          handleDragStart(e, {
                            type: item.type,
                            data: item.data ? { name: item.data.name } : null,
                          })
                        }
                      >
                        <DragIndicatorIcon fontSize="small" />
                        {item.type === 'image' && item.data.name}
                        {item.type === 'stac' && item.data.name}
                        {item.type === 'shape' && 'Drawn Shape'}
                      </DraggableItem>
                    ))}
                  </Box>
                </Box>
              </CardContent>

              <CardActions sx={{ position: 'absolute', bottom: '1px', width: 'calc(100% - 2px)' }}>
                <HoverButton
                  variant="contained"
                  color="secondary"
                  startIcon={<PlayArrowIcon />}
                  sx={{
                    backgroundColor: '#c5d1eb',
                    color: '#000000',
                    borderRadius: 1,
                    fontSize: 16,
                    border: '1px solid #aebfd1',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    fontWeight: 200,
                    textTransform: 'capitalize',
                    '&:hover': {
                      backgroundColor: '#0c0678',
                      color: '#FFFFFF',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  onClick={handleGenerate}
                  disabled={loader}
                >
                  {loader ? 'Synthesizing Your Image...' : 'Synthesize'}
                  {loader && (
                    <CircularProgress size={24} sx={{ marginLeft: 1, color: '#0c0678' }} />
                  )}
                </HoverButton>
              </CardActions>
            </StyledCard>
          </Grid>

          <Grid item xs={12} md={rightGridSize}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6">Output</Typography>
                <Tabs
                  value={outputTab}
                  onChange={handleOutputTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab label="Image" icon={<ImageIcon />} />
                  <Tab label="Data" icon={<InsertDriveFileIcon />} />
                </Tabs>
                <TabPanel value={outputTab} index={0}>
                  {loader ? (
                    <div className="shimmer-box"></div>
                  ) : (
                    <>
                      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                      {hasResults && apiResponse ? (
                        <div>
                          <Typography variant="h6">Generated Image</Typography>
                          <Card sx={{ position: 'relative' }}>
                            <img
                              src={apiResponse.satellite_simulation}
                              alt="Synthesized"
                              style={{
                                maxWidth: '100%',
                                borderRadius: '8px',
                                marginTop: '16px',
                              }}
                            />
                            <CardActions sx={{ position: 'absolute', top: 0, right: 0 }}>
                              <IconButton color="primary">
                                <ZoomInIcon />
                              </IconButton>
                              <IconButton color="primary">
                                <ZoomOutIcon />
                              </IconButton>
                              <IconButton color="primary">
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton color="primary">
                                <DownloadIcon />
                              </IconButton>
                            </CardActions>
                          </Card>
                        </div>
                      ) : (
                        <div className="no-results-container">
                          <LayersIcon fontSize="large" />
                          <Typography variant="h6">No results yet</Typography>
                          <Typography variant="body2">
                            Upload data or enter a prompt, and then click "Synthesize"
                          </Typography>
                        </div>
                      )}
                    </>
                  )}
                </TabPanel>
                <TabPanel value={outputTab} index={1}>
                  {hasResults && apiResponse ? (
                    <div>
                      <Typography variant="h6">Generated Data</Typography>
                      <Paper elevation={3} sx={{ padding: 2, marginTop: 2 }}>
                        <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
                      </Paper>
                    </div>
                  ) : (
                    <Typography variant="body2">No data available.</Typography>
                  )}
                </TabPanel>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>
      </StyledContainer>

      {/* Include other modals and components here as per your original code */}

      <style>{`
        .shimmer-box {
          width: 100%;
          height: 250px;
          background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
          background-size: 200% 100%;
          animation: shimmer 1.5s infinite;
          border-radius: 8px;
        }
        @keyframes shimmer {
          0% { background-position: 200% 0; }
          100% { background-position: -200% 0; }
        }
        .no-results-container {
          text-align: center;
          margin-top: 50px;
          color: #9e9e9e;
        }
        .animated-button {
          transition: all 0.3s ease;
        }
        .animated-button:hover {
          transform: translateY(-2px);
          box-shadow: 0px 4px 6px rgba(0,0,0,0.2);
        }
      `}</style>
    </MainWrapper>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{ paddingTop: '16px' }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function MapSearch({ mapLocationQuery, setSearchResults }) {
  const map = useMap();

  useEffect(() => {
    if (mapLocationQuery.trim() === '') {
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
            mapLocationQuery
          )}&format=json&limit=5`
        );

        if (response.data.length > 0) {
          setSearchResults(response.data);
        } else {
          alert('Location not found.');
        }
      } catch (error) {
        alert('Failed to search location. Please try again.');
      }
    };

    fetchData();
  }, [mapLocationQuery, map, setSearchResults]);

  return null;
}

export default NavigationalPlayground;
