// src/pages/billing/Billing.jsx

import React, { useState, useMemo } from "react";
import MainWrapper from "../wrapper/MainWrapper";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Table,
  Modal,
  Badge,
  OverlayTrigger,
  Tooltip as BootstrapTooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSort,
  faCreditCard,
  faWallet,
  faChartLine,
  faHistory,
  faCogs,
  faCoins,
  faDownload,
  faEye,
  faCheck,
  faTrash,
  faEdit,
  faSyncAlt,
  faBell,
  faFileInvoiceDollar,
  faBitcoin,
  faUserShield,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faBitcoin as faBitcoinBrand } from "@fortawesome/free-brands-svg-icons";
import { CSSTransition } from "react-transition-group";
import Chart from "react-apexcharts";
import "../../css/style.css";
import "./animations.css";
import { Card as MuiCard, CardContent, Typography } from "@mui/material";

const Billing = () => {
  //const [activeTab, setActiveTab] = useState("overview");
  const [activeTab, setActiveTab] = useState("billingHistory");
  const navigate = useNavigate();

  // State for Modals
  const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = useState(false);

  // Updated KPIs Data
  const currentBalance = "$1,250.00";
  const totalSpendThisMonth = "$750.00";
  const availableCredits = 5000;
  const upcomingInvoiceAmount = "$300.00";
  const paymentDueDate = "November 15, 2024";
  const lastPaymentAmount = "$600.00";
  const lastPaymentDate = "October 15, 2024";

  // Sample Data for Charts
  const spendTrendData = useMemo(
    () => ({
      series: [
        {
          name: "Monthly Spend",
          data: [500, 600, 650, 700, 750],
        },
      ],
      options: {
        chart: {
          type: "area",
          height: 200,
          toolbar: {
            show: false,
          },
        },
        colors: ["#007bff"],
        xaxis: {
          categories: ["June", "July", "August", "September", "October"],
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => `$${val}`,
          },
        },
        tooltip: {
          y: {
            formatter: (val) => `$${val}`,
          },
        },
      },
    }),
    []
  );

  // Sample Billing History Data
  const billingHistory = useMemo(
    () => [
      {
        id: 1,
        date: "2024-10-15",
        invoiceNumber: "ACME-Invoice-1024",
        amount: "$750.00",
        status: "Unpaid",
      },
      {
        id: 2,
        date: "2024-09-15",
        invoiceNumber: "ACME-Invoice-1023",
        amount: "$700.00",
        status: "Unpaid",
      },
      {
        id: 3,
        date: "2024-08-15",
        invoiceNumber: "ACME-Invoice-1022",
        amount: "$650.00",
        status: "Paid",
      },
      {
        id: 4,
        date: "2024-08-15",
        invoiceNumber: "ACME-Invoice-1022",
        amount: "$450.00",
        status: "Paid",
      },
      // Add more entries as needed
    ],
    []
  );

  // Calculate Total Amount Due
  const totalAmountDue = useMemo(() => {
    return billingHistory
      .filter((invoice) => invoice.status !== "Paid")
      .reduce((acc, invoice) => {
        // Convert amount string to number
        const amountNumber = parseFloat(invoice.amount.replace(/[^0-9.-]+/g, ""));
        return acc + amountNumber;
      }, 0);
  }, [billingHistory]);

  // Payment Methods Data including Crypto
  const paymentMethods = useMemo(
    () => [
      {
        id: 1,
        type: "Credit Card",
        details: "Visa ending in 1234",
        isDefault: true,
        icon: faCreditCard,
      },
      {
        id: 2,
        type: "PayPal",
        details: "user@example.com",
        isDefault: false,
        icon: faWallet,
      },
      {
        id: 3,
        type: "Crypto Wallet",
        details: "BTC Wallet",
        isDefault: false,
        icon: faBitcoinBrand,
      },
    ],
    []
  );

  // State for Pay Invoice Modal
  const [showPayModal, setShowPayModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const handlePayInvoice = (invoice) => {
    setSelectedInvoice(invoice);
    setShowPayModal(true);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "overview":
        return (
          <div className="col-12">
            {/* KPIs Section */}
            <Row className="mb-4">
              <Col xs={12}>
                <Row className="g-3">
                  {/* Current Balance */}
                  <Col xs={12} md={4}>
                    <Card className="h-100 shadow-sm border-0">
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <div className="me-3">
                            <FontAwesomeIcon
                              icon={faWallet}
                              size="2x"
                              className="text-primary"
                            />
                          </div>
                          <div>
                            <h6 className="text-muted mb-1">Current Balance</h6>
                            <h4 className="fw-bold mb-0">{currentBalance}</h4>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  {/* Total Spend This Month */}
                  <Col xs={12} md={4}>
                    <Card className="h-100 shadow-sm border-0">
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <div className="me-3">
                            <FontAwesomeIcon
                              icon={faChartLine}
                              size="2x"
                              className="text-success"
                            />
                          </div>
                          <div>
                            <h6 className="text-muted mb-1">Total Spend This Month</h6>
                            <h4 className="fw-bold mb-0">{totalSpendThisMonth}</h4>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  {/* Available Credits */}
                  <Col xs={12} md={4}>
                    <Card className="h-100 shadow-sm border-0">
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <div className="me-3">
                            <FontAwesomeIcon
                              icon={faCoins}
                              size="2x"
                              className="text-warning"
                            />
                          </div>
                          <div>
                            <h6 className="text-muted mb-1">Available Credits</h6>
                            <h4 className="fw-bold mb-0">{availableCredits}</h4>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* Spend Trend Chart */}
            <Row className="mb-4">
              <Col xs={12}>
                <Card className="shadow-sm border-0">
                  <Card.Header className="bg-white border-0">
                    <h5 className="mb-0">
                      <FontAwesomeIcon icon={faChartLine} className="me-2" />
                      Monthly Spend Trend
                    </h5>
                  </Card.Header>
                  <Card.Body>
                    <Chart
                      options={spendTrendData.options}
                      series={spendTrendData.series}
                      type="area"
                      height={250}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* Upcoming Invoice and Last Payment */}
            <Row className="mb-4">
              {/* Upcoming Invoice */}
              <Col xs={12} md={6}>
                <Card className="shadow-sm border-0 h-100">
                  <Card.Body>
                    <div className="d-flex align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faFileInvoiceDollar}
                        size="2x"
                        className="text-danger me-3"
                      />
                      <div>
                        <h5 className="mb-0">Upcoming Invoice</h5>
                      </div>
                    </div>
                    <p className="mb-1">
                      <strong>Amount Due:</strong> {upcomingInvoiceAmount}
                    </p>
                    <p className="mb-1">
                      <strong>Due Date:</strong> {paymentDueDate}
                    </p>
                    <Button variant="primary" size="sm">
                      View Invoice
                    </Button>
                  </Card.Body>
                </Card>
              </Col>

              {/* Last Payment */}
              <Col xs={12} md={6}>
                <Card className="shadow-sm border-0 h-100">
                  <Card.Body>
                    <div className="d-flex align-items-center mb-3">
                      <FontAwesomeIcon
                        icon={faSyncAlt}
                        size="2x"
                        className="text-info me-3"
                      />
                      <div>
                        <h5 className="mb-0">Last Payment</h5>
                      </div>
                    </div>
                    <p className="mb-1">
                      <strong>Amount Paid:</strong> {lastPaymentAmount}
                    </p>
                    <p className="mb-1">
                      <strong>Date:</strong> {lastPaymentDate}
                    </p>
                    <Button variant="outline-secondary" size="sm">
                      View Receipt
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* Quick Actions */}
          </div>
        );
      case "paymentMethods":
        return (
          <div className="col-12">
            <h4 className="mb-4">
              <FontAwesomeIcon icon={faCreditCard} className="me-2" />
              Payment Methods
            </h4>
            {/* Payment Methods List */}
            <Row>
              {paymentMethods.map((method) => (
                <Col xs={12} md={6} key={method.id}>
                  <Card
                    className={`mb-3 shadow-sm ${
                      method.isDefault ? "border-primary" : "border-0"
                    }`}
                  >
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <FontAwesomeIcon
                            icon={method.icon}
                            size="2x"
                            className="me-3 text-secondary"
                          />
                          <div>
                            <h5 className="mb-1">{method.type}</h5>
                            <p className="mb-0 text-muted">{method.details}</p>
                          </div>
                        </div>
                        {method.isDefault && (
                          <Badge bg="primary" pill>
                            Default
                          </Badge>
                        )}
                      </div>
                    </Card.Body>
                    <Card.Footer className="bg-white border-0">
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        className="me-2"
                        onClick={() => alert("Edit Payment Method")}
                      >
                        <FontAwesomeIcon icon={faEdit} /> Edit
                      </Button>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => alert("Remove Payment Method")}
                      >
                        <FontAwesomeIcon icon={faTrash} /> Remove
                      </Button>
                      {!method.isDefault && (
                        <Button
                          variant="outline-success"
                          size="sm"
                          className="ms-2"
                          onClick={() => alert("Set as Default")}
                        >
                          <FontAwesomeIcon icon={faCheck} /> Set as Default
                        </Button>
                      )}
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
              <Col xs={12}>
                <Button
                  variant="primary"
                  onClick={() => setShowAddPaymentMethodModal(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add New Payment Method
                </Button>
              </Col>
            </Row>
          </div>
        );
      case "billingHistory":
        return (
          <div className="col-12">
            {/* KPI Card */}
            <MuiCard className="mb-4">
              <CardContent>
                <Typography variant="h6" component="div">
                  Total Amount Due
                </Typography>
                <Typography variant="h4" component="div">
                  ${totalAmountDue.toFixed(2)}
                </Typography>
              </CardContent>
            </MuiCard>
            <h4 className="mb-4">
              <FontAwesomeIcon icon={faHistory} className="me-2" />
              Billing History (Synthetic, actuals after Enterprise Agreement signing.)
            </h4>
            {/* Billing History Table */}
            <Card className="shadow-sm border-0">
              <Card.Body>
                <Table hover responsive className="mb-0">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Invoice Number</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th className="text-end">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {billingHistory.map((invoice) => (
                      <tr key={invoice.id}>
                        <td>{invoice.date}</td>
                        <td>{invoice.invoiceNumber}</td>
                        <td>{invoice.amount}</td>
                        <td>
                          <Badge
                            bg={invoice.status === "Paid" ? "success" : "warning"}
                          >
                            {invoice.status}
                          </Badge>
                        </td>
                        <td className="text-end">
                          <Button
                            variant="link"
                            size="sm"
                            onClick={() => alert("View Invoice")}
                          >
                            <FontAwesomeIcon icon={faEye} /> View
                          </Button>
                          <Button
                            variant="link"
                            size="sm"
                            onClick={() => alert("Download Invoice")}
                          >
                            <FontAwesomeIcon icon={faDownload} /> Download
                          </Button>
                          {invoice.status !== "Paid" && (
                            <Button
                              variant="link"
                              size="sm"
                              onClick={() => handlePayInvoice(invoice)}
                            >
                              <FontAwesomeIcon icon={faCreditCard} /> Pay
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </div>
        );
      case "preferences":
        return (
          <div className="col-12">
            <h4 className="mb-4">
              <FontAwesomeIcon icon={faCogs} className="me-2" />
              Billing Preferences
            </h4>
            <Form>
              {/* Billing Email */}
              <Form.Group className="mb-3" controlId="billingEmail">
                <Form.Label>Billing Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter billing email"
                  defaultValue="user@example.com"
                />
                <Form.Text className="text-muted">
                  Invoices and billing notifications will be sent to this email.
                </Form.Text>
              </Form.Group>

              {/* Notification Preferences */}
              <Form.Group className="mb-3" controlId="notificationPreferences">
                <Form.Label>Notification Preferences</Form.Label>
                <Form.Check
                  type="checkbox"
                  label="Send me billing reminders"
                  defaultChecked
                />
                <Form.Check
                  type="checkbox"
                  label="Notify me about promotions and updates"
                />
              </Form.Group>

              {/* Save Button */}
              <Button variant="primary" type="submit">
                Save Preferences
              </Button>
            </Form>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <MainWrapper>
      <div className="container-fluid mt-4" style={{ paddingBottom: "70px" }}>
        {/* Page Header */}
        <Row className="mb-4">
          <Col xs={12}>
            <h2 className="fw-bold">Billing Dashboard (Synthetic)</h2>
          </Col>
        </Row>

        {/* Navigation Tabs with Icons */}
        <Row className="mb-4">
          <Col xs={12}>
            <ul className="nav nav-pills">
              {/* <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "overview" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("overview")}
                >
                  <FontAwesomeIcon icon={faWallet} className="me-2" />
                  Overview
                </button>
              </li> */}
              {/* <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "paymentMethods" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("paymentMethods")}
                >
                  <FontAwesomeIcon icon={faCreditCard} className="me-2" />
                  Payment Methods
                </button>
              </li> */}
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "billingHistory" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("billingHistory")}
                >
                  <FontAwesomeIcon icon={faHistory} className="me-2" />
                  Monthly Billing cycle.
                </button>
              </li>
              {/* <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "preferences" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("preferences")}
                >
                  <FontAwesomeIcon icon={faCogs} className="me-2" />
                  Preferences
                </button>
              </li> */}
            </ul>
          </Col>
        </Row>

        {/* Main Content */}
        <Row>
          <CSSTransition
            in={true}
            appear={true}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <div className="w-100">{renderContent()}</div>
          </CSSTransition>
        </Row>
      </div>

      {/* Add Payment Method Modal */}
      <Modal
        show={showAddPaymentMethodModal}
        onHide={() => setShowAddPaymentMethodModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Payment Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Payment Method Type */}
            <Form.Group controlId="paymentMethodType" className="mb-3">
              <Form.Label>Payment Method Type</Form.Label>
              <Form.Select>
                <option value="card">Credit/Debit Card</option>
                <option value="paypal">PayPal</option>
                <option value="crypto">Crypto Wallet</option>
              </Form.Select>
            </Form.Group>

            {/* Payment Details */}
            <Form.Group controlId="paymentDetails" className="mb-3">
              <Form.Label>Payment Details</Form.Label>
              <Form.Control type="text" placeholder="Enter payment details" />
            </Form.Group>

            <Button variant="primary" onClick={() => alert("Payment Method Added!")}>
              Add Payment Method
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Pay Invoice Modal */}
      <Modal
        show={showPayModal}
        onHide={() => setShowPayModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Pay Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedInvoice && (
            <div>
              <p>
                <strong>Invoice Number:</strong> {selectedInvoice.invoiceNumber}
              </p>
              <p>
                <strong>Amount Due:</strong> {selectedInvoice.amount}
              </p>
              {/* Payment Options Form */}
              <Form>
                <Form.Group controlId="paymentMethod" className="mb-3">
                  <Form.Label>Select Payment Method</Form.Label>
                  <Form.Select>
                    {paymentMethods.map((method) => (
                      <option key={method.id} value={method.id}>
                        {method.type} - {method.details}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Button
                  variant="primary"
                  onClick={() => {
                    alert("Payment Successful!");
                    setShowPayModal(false);
                  }}
                >
                  Pay Now
                </Button>
              </Form>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </MainWrapper>
  );
};

export default Billing;
