import { useState, useEffect } from "react";
import MainWrapper from "../../component/wrapper/MainWrapper";
import {
  Skeleton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  MenuItem,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import {
  ContentCopy as ContentCopyIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import * as XLSX from "xlsx/xlsx.mjs";

const Transactions = () => {
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [sortMethod, setSortMethod] = useState("newestFirst");
  const [filter, setFilter] = useState("All");
  const [transactions, setTransactions] = useState([]);

  // Simulated API call
  const fetchTransactions = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const data = [
          {
            id: 1,
            model: "Images",
            synthesizedOn: "2024-01-12T20:00:00Z",
            synthesizedOnFormatted: "12th Jan 2024 | 20:00:00",
            ranBy: "ACME Corp.",
            jobHash: "0xdlfdsjflkjsdkfljds",
            totalTokensUsed: "3",
            totalCost: "2500 USDT",
            totalCostValue: 2500,
            status: "Pending",
          },
          {
            id: 2,
            model: "Images",
            synthesizedOn: "2024-01-15T15:30:00Z",
            synthesizedOnFormatted: "15th Jan 2024 | 15:30:00",
            ranBy: "ACME Corp.",
            jobHash: "0xabcdef1234567890",
            totalTokensUsed: "2",
            totalCost: "1500 USDT",
            totalCostValue: 1500,
            status: "Completed",
          },
          {
            id: 3,
            model: "Audio",
            synthesizedOn: "2024-01-20T10:15:00Z",
            synthesizedOnFormatted: "20th Jan 2024 | 10:15:00",
            ranBy: "ACME Corp.",
            jobHash: "0xa1b2c3d4e5f67890",
            totalTokensUsed: "5",
            totalCost: "3500 USDT",
            totalCostValue: 3500,
            status: "Failed",
          },
          {
            id: 4,
            model: "Video",
            synthesizedOn: "2024-01-22T22:00:00Z",
            synthesizedOnFormatted: "22nd Jan 2024 | 22:00:00",
            ranBy: "ACME Corp.",
            jobHash: "0x123456789abcdef0",
            totalTokensUsed: "4",
            totalCost: "3000 USDT",
            totalCostValue: 3000,
            status: "Pending",
          },
          {
            id: 5,
            model: "Images",
            synthesizedOn: "2024-01-25T08:00:00Z",
            synthesizedOnFormatted: "25th Jan 2024 | 08:00:00",
            ranBy: "ACME Corp.",
            jobHash: "0x0987654321fedcba",
            totalTokensUsed: "4",
            totalCost: "3200 USDT",
            totalCostValue: 3200,
            status: "Completed",
          },
          {
            id: 6,
            model: "GPS",
            synthesizedOn: "2024-01-28T12:00:00Z",
            synthesizedOnFormatted: "28th Jan 2024 | 12:00:00",
            ranBy: "ACME Corp.",
            jobHash: "0x123abcfed4567890",
            totalTokensUsed: "2",
            totalCost: "1800 USDT",
            totalCostValue: 1800,
            status: "Completed",
          },
          {
            id: 7,
            model: "Text",
            synthesizedOn: "2024-01-30T14:20:00Z",
            synthesizedOnFormatted: "30th Jan 2024 | 14:20:00",
            ranBy: "ACME Corp.",
            jobHash: "0xa1b2c3d4e5f6789f",
            totalTokensUsed: "5",
            totalCost: "4200 USDT",
            totalCostValue: 4200,
            status: "Failed",
          },
          {
            id: 8,
            model: "Audio",
            synthesizedOn: "2024-02-02T16:45:00Z",
            synthesizedOnFormatted: "2nd Feb 2024 | 16:45:00",
            ranBy: "ACME Corp.",
            jobHash: "0xabcdef123456f789",
            totalTokensUsed: "3",
            totalCost: "2650 USDT",
            totalCostValue: 2650,
            status: "Pending",
          },
          {
            id: 9,
            model: "Satellite",
            synthesizedOn: "2024-02-05T18:30:00Z",
            synthesizedOnFormatted: "5th Feb 2024 | 18:30:00",
            ranBy: "ACME Corp.",
            jobHash: "0xabcdef9876543210",
            totalTokensUsed: "7",
            totalCost: "2875 USDT",
            totalCostValue: 2875,
            status: "Completed",
          },
          {
            id: 10,
            model: "Drone",
            synthesizedOn: "2024-02-10T20:00:00Z",
            synthesizedOnFormatted: "10th Feb 2024 | 20:00:00",
            ranBy: "ACME Corp.",
            jobHash: "0x0123456789abcdef",
            totalTokensUsed: "4",
            totalCost: "3600 USDT",
            totalCostValue: 3600,
            status: "Pending",
          },
          // Add additional transaction objects here...
        ];
        resolve(data);
      }, 1000); // Simulate network delay
    });
  };

  useEffect(() => {
    setTransactionLoading(true);
    fetchTransactions().then((data) => {
      setTransactions(data);
      setTransactionLoading(false);
    });
  }, []);

  // Filter transactions
  const filteredTransactions = transactions.filter((txn) => {
    if (filter === "All") return true;
    return txn.status === filter;
  });

  // Sort transactions
  const sortedTransactions = [...filteredTransactions].sort((a, b) => {
    switch (sortMethod) {
      case "newestFirst":
        return new Date(b.synthesizedOn) - new Date(a.synthesizedOn);
      case "oldestFirst":
        return new Date(a.synthesizedOn) - new Date(b.synthesizedOn);
      case "costliestToFree":
        return b.totalCostValue - a.totalCostValue;
      case "freeToCostliest":
        return a.totalCostValue - b.totalCostValue;
      default:
        return 0;
    }
  });

  const handleDownloadTransaction = () => {
    const data = transactions.map((txn) => ({
      "Job ID": txn.id,
      Model: txn.model,
      "Synthesized On": txn.synthesizedOnFormatted,
      "Ran By": txn.ranBy,
      "Job Hash": txn.jobHash,
      "Total Tokens Used": txn.totalTokensUsed,
      "Total Cost": txn.totalCost,
      Status: txn.status,
    }));

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
    const filename = `transaction-history_${timestamp}.xlsx`;

    XLSX.writeFile(wb, filename);
  };

  const toggleDrawer = (transaction) => {
    setSelectedTransaction(transaction);
    setDrawerOpen(!drawerOpen);
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const InputTextField = styled(TextField)({
    margin: "10px",
    width: "25%",
  });

  const handleSortChange = (event) => {
    setSortMethod(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <MainWrapper>
      <div className="container-fluid">
        {transactionLoading ? (
          <SkeletonLoader />
        ) : (
          <>
            <div className="row">
              <div className="col-xl-12 col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="w-100 d-flex justify-content-between  align-items-center">
                      <h5 className="text-center my-2">Transaction History : Synthetic, actuals post deployment on-chain / off-chain.</h5>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDownloadTransaction}
                      >
                        Download
                      </Button>
                    </div>
                    <div className="d-flex flex-wrap">
                      <InputTextField
                        label="Sort By"
                        select
                        value={sortMethod}
                        onChange={handleSortChange}
                        helperText="Select sorting method"
                        variant="outlined"
                      >
                        <MenuItem value="newestFirst">Newest to Oldest</MenuItem>
                        <MenuItem value="oldestFirst">Oldest to Newest</MenuItem>
                        <MenuItem value="costliestToFree">Costliest to Free</MenuItem>
                        <MenuItem value="freeToCostliest">Free to Costliest</MenuItem>
                      </InputTextField>
                      <InputTextField
                        label="Filter By"
                        select
                        value={filter}
                        onChange={handleFilterChange}
                        helperText="Select filter"
                        variant="outlined"
                      >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem value="Pending">Pending</MenuItem>
                        <MenuItem value="Completed">Completed</MenuItem>
                        <MenuItem value="Failed">Failed</MenuItem>
                      </InputTextField>
                    </div>
                    <TransactionTable
                      transactions={sortedTransactions}
                      toggleDrawer={toggleDrawer}
                      handleCopyToClipboard={handleCopyToClipboard}
                    />
                  </div>
                </div>
              </div>
            </div>
            <TransactionDrawer
              drawerOpen={drawerOpen}
              setDrawerOpen={setDrawerOpen}
              selectedTransaction={selectedTransaction}
              handleCopyToClipboard={handleCopyToClipboard}
            />
          </>
        )}
      </div>
    </MainWrapper>
  );
};

const SkeletonLoader = () => (
  <div className="pt-[5%] w-75 mx-auto">
    {Array.from({ length: 12 }).map((_, index) => (
      <Skeleton
        key={index}
        variant="text"
        sx={{ fontSize: "1rem" }}
        className="w-[80%] mx-auto"
      />
    ))}
  </div>
);

const TransactionTable = ({
  transactions,
  toggleDrawer,
  handleCopyToClipboard,
}) => (
  <TableContainer component={Paper}>
    <Table sx={{ minWidth: 845 }} aria-label="transaction table">
      <TableHead>
        <TableRow>
          <TableCell align="center">Job ID</TableCell>
          <TableCell align="center">Model</TableCell>
          <TableCell align="center">Synthesized On</TableCell>
          <TableCell align="center">Ran By</TableCell>
          <TableCell align="center">Job Hash</TableCell>
          <TableCell align="center">Total Tokens Used</TableCell>
          <TableCell align="center">Total Cost</TableCell>
          <TableCell align="center">Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transactions.map((txn) => (
          <TableRow
            key={txn.id}
            onClick={() => toggleDrawer(txn)}
            hover
            style={{ cursor: "pointer" }}
          >
            <TableCell align="center">{txn.id}</TableCell>
            <TableCell align="center">{txn.model}</TableCell>
            <TableCell align="center">{txn.synthesizedOnFormatted}</TableCell>
            <TableCell align="center">{txn.ranBy}</TableCell>
            <TableCell align="center">
              {txn.jobHash}
              <Tooltip title="Copy Job Hash">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyToClipboard(txn.jobHash);
                  }}
                  size="small"
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell align="center">{txn.totalTokensUsed}</TableCell>
            <TableCell align="center">{txn.totalCost}</TableCell>
            <TableCell
              align="center"
              style={{ color: getStatusColor(txn.status) }}
            >
              {txn.status}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const getStatusColor = (status) => {
  switch (status) {
    case "Completed":
      return "green";
    case "Failed":
      return "red";
    case "Pending":
      return "orange";
    default:
      return "black";
  }
};

const TransactionDrawer = ({
  drawerOpen,
  setDrawerOpen,
  selectedTransaction,
  handleCopyToClipboard,
}) => (
  <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
    <div style={{ width: 350, padding: 20 }}>
      <h2>Transaction Details</h2>
      {selectedTransaction && (
        <List>
          <ListItem>
            <ListItemText primary="Job ID" secondary={selectedTransaction.id} />
            <Tooltip title="Copy Job ID">
              <IconButton
                onClick={() => handleCopyToClipboard(selectedTransaction.id)}
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Model"
              secondary={selectedTransaction.model}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Synthesized On"
              secondary={selectedTransaction.synthesizedOnFormatted}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Ran By"
              secondary={selectedTransaction.ranBy}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Job Hash"
              secondary={selectedTransaction.jobHash}
            />
            <Tooltip title="Copy Job Hash">
              <IconButton
                onClick={() =>
                  handleCopyToClipboard(selectedTransaction.jobHash)
                }
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Total Tokens Used"
              secondary={selectedTransaction.totalTokensUsed}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Total Cost"
              secondary={selectedTransaction.totalCost}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Status"
              secondary={selectedTransaction.status}
              style={{ color: getStatusColor(selectedTransaction.status) }}
            />
          </ListItem>
        </List>
      )}
    </div>
  </Drawer>
);

export default Transactions;
