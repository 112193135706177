import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faCopy } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx/xlsx.mjs";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import axios from "axios";

const TransactionsTab = () => {
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [transactionSearchQuery, setTransactionSearchQuery] = useState("");
  const [sortMethod, setSortMethod] = useState("newestFirst");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    try {
      const response = await axios.get("/transactions/list");
      setTransactions(response.data);
      setFilteredTransactions(response.data);
    } catch (error) {
      console.error("Failed to fetch transactions", error);
    }
  };

  useEffect(() => {
    let sortedTransactions = [...transactions];
    if (sortMethod === "newestFirst") {
      sortedTransactions.sort((a, b) => new Date(b.synthesizedOn) - new Date(a.synthesizedOn));
    } else if (sortMethod === "oldestFirst") {
      sortedTransactions.sort((a, b) => new Date(a.synthesizedOn) - new Date(b.synthesizedOn));
    }
    setFilteredTransactions(sortedTransactions);
  }, [sortMethod, transactions]);

  const handleDownloadTransaction = () => {
    const data = filteredTransactions.map((txn) => ({
      "Job ID": txn.id,
      Model: txn.model,
      "Synthesized On": txn.synthesizedOn,
      "Ran By": txn.ranBy,
      "Job Hash": txn.jobHash,
      "Total Tokens Used": txn.totalTokensUsed,
      "Total Cost": txn.totalCost,
      Status: txn.status,
    }));

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Transactions");
    XLSX.writeFile(wb, "transaction_history.xlsx");
  };

  const toggleDrawer = (transaction) => {
    setSelectedTransaction(transaction);
    setDrawerOpen(!drawerOpen);
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard");
  };

  return (
    <Card className="p-3">
      <h4>Transactions</h4>
      <p>Track transactions related to your project.</p>

      {/* Sort and Search Section */}
      <Row className="mt-4 mb-3">
        <Col md={6} sm={12} className="mb-2">
          <Form.Control
            type="text"
            placeholder="Search transactions..."
            value={transactionSearchQuery}
            onChange={(e) => setTransactionSearchQuery(e.target.value)}
            aria-label="Search Transactions"
          />
        </Col>
        <Col md={6} sm={12} className="text-md-end">
          <Form.Select value={sortMethod} onChange={(e) => setSortMethod(e.target.value)}>
            <option value="newestFirst">Newest to Oldest</option>
            <option value="oldestFirst">Oldest to Newest</option>
          </Form.Select>
        </Col>
      </Row>

      {/* Transactions Table */}
      <Table responsive bordered hover>
        <thead className="table-light">
          <tr>
            <th>ID</th>
            <th>Model</th>
            <th>Synthesized On</th>
            <th>Ran By</th>
            <th>Job Hash</th>
            <th>Total Tokens Used</th>
            <th>Total Cost</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredTransactions.length === 0 ? (
            <tr>
              <td colSpan="8" className="text-center">
                No transactions found.
              </td>
            </tr>
          ) : (
            filteredTransactions.map((txn) => (
              <tr key={txn.id} onClick={() => toggleDrawer(txn)}>
                <td>{txn.id}</td>
                <td>{txn.model}</td>
                <td>{txn.synthesizedOn}</td>
                <td>{txn.ranBy}</td>
                <td>
                  {txn.jobHash}
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopyToClipboard(txn.jobHash);
                    }}
                    aria-label={`Copy Job Hash ${txn.jobHash}`}
                    size="small"
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </IconButton>
                </td>
                <td>{txn.totalTokensUsed}</td>
                <td>{txn.totalCost}</td>
                <td>{txn.status}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      {/* Download Button */}
      <div className="d-flex justify-content-end">
        <Button variant="primary" onClick={handleDownloadTransaction}>
          <FontAwesomeIcon icon={faDownload} className="me-2" />
          Download Transactions
        </Button>
      </div>

      {/* Transaction Drawer */}
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div style={{ width: "350px", padding: "20px" }}>
          {selectedTransaction ? (
            <>
              <h5>Transaction Details</h5>
              <List>
                <ListItem>
                  <ListItemText primary="Job ID" secondary={selectedTransaction.id} />
                  <IconButton onClick={() => handleCopyToClipboard(selectedTransaction.id.toString())}>
                    <FontAwesomeIcon icon={faCopy} />
                  </IconButton>
                </ListItem>
                <ListItem>
                  <ListItemText primary="Model" secondary={selectedTransaction.model} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Synthesized On" secondary={selectedTransaction.synthesizedOn} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Ran By" secondary={selectedTransaction.ranBy} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Job Hash" secondary={selectedTransaction.jobHash} />
                  <IconButton onClick={() => handleCopyToClipboard(selectedTransaction.jobHash)}>
                    <FontAwesomeIcon icon={faCopy} />
                  </IconButton>
                </ListItem>
                <ListItem>
                  <ListItemText primary="Total Tokens Used" secondary={selectedTransaction.totalTokensUsed} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Total Cost" secondary={selectedTransaction.totalCost} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Status" secondary={selectedTransaction.status} />
                </ListItem>
              </List>
            </>
          ) : (
            <p>Select a transaction to see details.</p>
          )}
        </div>
      </Drawer>
    </Card>
  );
};

export default TransactionsTab;