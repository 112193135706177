// ScheduleTab.js - Component for the Schedule Tab

import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Grid,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  IconButton,
  Tooltip,
  CircularProgress,
  Chip,
  Paper,
  Tabs,
  Tab,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import {
  Event as EventIcon,
  Edit as EditIcon,
  Add as AddIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
  Refresh as RefreshIcon,
  Info as InfoIcon,
  Schedule as ScheduleIcon,
  List as ListIcon,
  Today as TodayIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ViewWeek as ViewWeekIcon,
  ViewModule as ViewModuleIcon,
  ViewAgenda as ViewAgendaIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import addDays from 'date-fns/addDays';
import { addHours } from 'date-fns';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import parseISO from 'date-fns/parseISO';
import enUS from 'date-fns/locale/en-US';
import 'react-big-calendar/lib/css/react-big-calendar.css'; // Import default styles
import useAuthorizedHttp from '../../hooks/use-authorized-http'; // Custom hook for API calls

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const CalendarWrapper = styled('div')(({ theme }) => ({
  '& .rbc-toolbar': {
    marginBottom: theme.spacing(2),
  },
  '& .rbc-toolbar-label': {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  '& .rbc-button-link': {
    color: theme.palette.text.primary,
    fontSize: '1rem',
    margin: theme.spacing(0, 0.5),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  '& .rbc-event': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '8px',
    padding: theme.spacing(0.5),
    color: '#fff',
    border: 'none',
    boxShadow: theme.shadows[2],
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  '& .rbc-selected': {
    backgroundColor: theme.palette.primary.dark,
  },
  '& .rbc-today': {
    backgroundColor: theme.palette.action.selected,
  },
  '& .rbc-off-range-bg': {
    backgroundColor: theme.palette.action.disabledBackground,
  },
  '& .rbc-time-view': {
    '& .rbc-time-header': {
      '& .rbc-time-header-cell': {
        textAlign: 'center',
        backgroundColor: theme.palette.background.default,
      },
    },
  },
}));

const CalendarTab = () => {
  const [projectData, setProjectData] = useState({
    startDate: null,
    endDate: null,
    deliveryFrequency: 'Weekly', // or 'Daily', 'Monthly'
  });
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [openEventDialog, setOpenEventDialog] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const makeRequest = useAuthorizedHttp(); // Custom hook for making API calls

  useEffect(() => {
    // Fetch project data from APIs
    const fetchData = async () => {
      try {
        setLoading(true);
        // Replace with actual API call
        setTimeout(() => {
          setProjectData({
            startDate: '2023-10-01',
            endDate: '2023-12-31',
            deliveryFrequency: 'Weekly',
          });
          setLoading(false);
        }, 1000);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchData();
  }, [makeRequest]);

  useEffect(() => {
    if (projectData.startDate && projectData.endDate) {
      generateEvents();
    }
  }, [projectData]);

  const generateEvents = () => {
    const { startDate, endDate, deliveryFrequency } = projectData;
    let events = [];
    let currentDate = parseISO(startDate);
    const end = parseISO(endDate);
    let freq = 7; // default weekly

    if (deliveryFrequency === 'Daily') {
      freq = 1;
    } else if (deliveryFrequency === 'Weekly') {
      freq = 7;
    } else if (deliveryFrequency === 'Biweekly') {
      freq = 14;
    } else if (deliveryFrequency === 'Monthly') {
      freq = 30;
    }

    while (isBefore(currentDate, end) || isSameDay(currentDate, end)) {
      events.push({
        title: 'Data Synthesis',
        start: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          9,
          0,
          0
        ),
        end: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          10,
          0,
          0
        ),
        allDay: false,
      });
      currentDate = addDays(currentDate, freq);
    }
    setEvents(events);
  };

  const handleEventSelect = (event) => {
    setCurrentEvent(event);
    setOpenEventDialog(true);
  };

  const handleEventSave = () => {
    const updatedEvents = events.map((evt) =>
      evt.start === currentEvent.start ? currentEvent : evt
    );
    setEvents(updatedEvents);
    setOpenEventDialog(false);
    setSnackbarOpen(true);
  };

  const handleEventDelete = () => {
    const updatedEvents = events.filter((evt) => evt.start !== currentEvent.start);
    setEvents(updatedEvents);
    setOpenEventDialog(false);
    setSnackbarOpen(true);
  };

  const handleAddEvent = () => {
    setCurrentEvent({
      title: '',
      start: new Date(),
      end: addHours(new Date(), 1),
      allDay: false,
    });
    setOpenEventDialog(true);
  };

  const handleEventDialogClose = () => {
    setOpenEventDialog(false);
    setCurrentEvent(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: isSelected ? '#1976d2' : '#2196f3',
      borderRadius: '8px',
      opacity: 0.9,
      color: 'white',
      border: 'none',
      display: 'block',
      padding: '4px',
      boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
    };
    return {
      style: style,
    };
  };

  const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      toolbar.onNavigate('PREV');
    };

    const goToNext = () => {
      toolbar.onNavigate('NEXT');
    };

    const goToToday = () => {
      toolbar.onNavigate('TODAY');
    };

    const handleViewChange = (view) => {
      toolbar.onView(view);
    };

    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box>
          <Button
            onClick={goToBack}
            startIcon={<ChevronLeftIcon />}
            sx={{
              margin: (theme) => theme.spacing(0, 0.5),
              color: (theme) => theme.palette.text.primary,
              '&:hover': {
                backgroundColor: (theme) => theme.palette.action.hover,
              },
            }}
          >
            Back
          </Button>
          <Button
            onClick={goToNext}
            endIcon={<ChevronRightIcon />}
            sx={{
              margin: (theme) => theme.spacing(0, 0.5),
              color: (theme) => theme.palette.text.primary,
              '&:hover': {
                backgroundColor: (theme) => theme.palette.action.hover,
              },
            }}
          >
            Next
          </Button>
          <Button onClick={goToToday} sx={{
              margin: (theme) => theme.spacing(0, 0.5),
              color: (theme) => theme.palette.text.primary,
              '&:hover': {
                backgroundColor: (theme) => theme.palette.action.hover,
              },
            }}>
            Today
          </Button>
        </Box>
        <Typography variant="h5" color="primary">
          {toolbar.label}
        </Typography>
        <Box>
          <Tooltip title="Month View">
            <IconButton onClick={() => handleViewChange('month')}>
              <ViewModuleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Week View">
            <IconButton onClick={() => handleViewChange('week')}>
              <ViewWeekIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Day View">
            <IconButton onClick={() => handleViewChange('day')}>
              <TodayIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Agenda View">
            <IconButton onClick={() => handleViewChange('agenda')}>
              <ViewAgendaIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  };

  const handleProjectDetailEdit = () => {
    // Implement logic to edit project details
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h4" gutterBottom>
        Schedule
      </Typography>
      <Typography variant="body1" gutterBottom>
        Manage your project schedule and delivery dates.
      </Typography>

      {loading ? (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <CircularProgress />
          <Typography variant="body1" sx={{ mt: 2 }}>
            Loading schedule...
          </Typography>
        </Box>
      ) : (
        <>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={8}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="h6">
                  Project Duration: {format(parseISO(projectData.startDate), 'MMMM do, yyyy')} -{' '}
                  {format(parseISO(projectData.endDate), 'MMMM do, yyyy')}
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  <strong>Delivery Frequency:</strong> {projectData.deliveryFrequency}
                </Typography>
                <Button
                  variant="outlined"
                  startIcon={<EditIcon />}
                  sx={{ mt: 2 }}
                  onClick={handleProjectDetailEdit}
                >
                  Edit Project Details
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'left', md: 'right' } }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddEvent}
              >
                Add Event
              </Button>
            </Grid>
          </Grid>

          <Box sx={{ mt: 4 }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="Schedule Tabs"
              variant="fullWidth"
              centered
              textColor="primary"
              indicatorColor="primary"
            >
              <Tab icon={<TodayIcon />} label="Calendar View" />
              <Tab icon={<ListIcon />} label="Event List" />
            </Tabs>

            {tabValue === 0 && (
              <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
                <CalendarWrapper>
                  <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 600 }}
                    eventPropGetter={eventStyleGetter}
                    onSelectEvent={handleEventSelect}
                    selectable
                    onSelectSlot={(slotInfo) => {
                      setCurrentEvent({
                        title: '',
                        start: slotInfo.start,
                        end: slotInfo.end,
                        allDay: slotInfo.action === 'doubleClick',
                      });
                      setOpenEventDialog(true);
                    }}
                    components={{
                      toolbar: CustomToolbar,
                    }}
                  />
                </CalendarWrapper>
              </Paper>
            )}

            {tabValue === 1 && (
              <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
                <List>
                  {events.map((event, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        secondaryAction={
                          <>
                            <Tooltip title="Edit">
                              <IconButton
                                edge="end"
                                aria-label="edit"
                                onClick={() => {
                                  setCurrentEvent(event);
                                  setOpenEventDialog(true);
                                }}
                                sx={{
                                  backgroundColor: 'transparent',
                                  '&:hover': {
                                    backgroundColor: 'rgba(25, 118, 210, 0.08)',
                                  },
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => {
                                  setCurrentEvent(event);
                                  handleEventDelete();
                                }}
                                sx={{
                                  backgroundColor: 'transparent',
                                  '&:hover': {
                                    backgroundColor: 'rgba(211, 47, 47, 0.08)',
                                  },
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <EventIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={event.title || 'Untitled Event'}
                          secondary={format(event.start, 'MMMM do yyyy, h:mm a')}
                        />
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}
                </List>
              </Paper>
            )}
          </Box>

          {/* Event Dialog */}
          <Dialog
            open={openEventDialog}
            onClose={handleEventDialogClose}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>{currentEvent?.title ? 'Edit Event' : 'Add Event'}</DialogTitle>
            <DialogContent>
              <TextField
                label="Event Title"
                fullWidth
                margin="normal"
                value={currentEvent?.title || ''}
                onChange={(e) =>
                  setCurrentEvent({ ...currentEvent, title: e.target.value })
                }
              />
              <TextField
                label="Start Date and Time"
                type="datetime-local"
                fullWidth
                margin="normal"
                value={format(currentEvent?.start || new Date(), "yyyy-MM-dd'T'HH:mm")}
                onChange={(e) =>
                  setCurrentEvent({
                    ...currentEvent,
                    start: parseISO(e.target.value),
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="End Date and Time"
                type="datetime-local"
                fullWidth
                margin="normal"
                value={format(currentEvent?.end || new Date(), "yyyy-MM-dd'T'HH:mm")}
                onChange={(e) =>
                  setCurrentEvent({
                    ...currentEvent,
                    end: parseISO(e.target.value),
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentEvent?.allDay || false}
                    onChange={(e) =>
                      setCurrentEvent({ ...currentEvent, allDay: e.target.checked })
                    }
                  />
                }
                label="All Day Event"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleEventDialogClose}
                color="secondary"
                startIcon={<CloseIcon />}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={handleEventSave}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          {/* Snackbar */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            message="Changes saved successfully"
            action={
              <IconButton size="small" aria-label="close" onClick={handleSnackbarClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </>
      )}
    </Box>
  );
};

export default CalendarTab;