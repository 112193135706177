// ConnectionsTab.js - Enhanced Component for Managing Connections

import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Button,
  Grid,
  TextField,
  Typography,
  Snackbar,
  Tabs,
  Tab,
  AppBar,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  Avatar,
  ListItemAvatar,
  ListItemText,
  ListItem,
  List,
  Divider,
  CircularProgress,
  Badge,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Storage as StorageIcon,
  Cloud as CloudIcon,
  Http as HttpIcon,
  Code as CodeIcon,
  CloudUpload as CloudUploadIcon,
  Close as CloseIcon,
  Save as SaveIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  VerifiedUser as VerifiedUserIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import useAuthorizedHttp from '../../hooks/use-authorized-http'; // Custom hook for API calls

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  '&:hover': {
    boxShadow: theme.shadows[6],
    transition: 'box-shadow 0.3s',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const connectionTypes = [
  { value: 'Database', label: 'Database', icon: <StorageIcon /> },
  { value: 'Cloud Storage', label: 'Cloud Storage', icon: <CloudUploadIcon /> },
  { value: 'Webhook', label: 'Webhook', icon: <HttpIcon /> },
  { value: 'API', label: 'API', icon: <CodeIcon /> },
  { value: 'Cloud Bucket', label: 'Cloud Bucket', icon: <CloudIcon /> },
];

const statusOptions = [
  { value: 'Active', label: 'Active', icon: <CheckCircleIcon color="success" /> },
  { value: 'Inactive', label: 'Inactive', icon: <ErrorIcon color="error" /> },
];

const ConnectionsTab = () => {
  const [connections, setConnections] = useState([]);
  const [filteredConnections, setFilteredConnections] = useState([]);
  const [connectionSearchQuery, setConnectionSearchQuery] = useState('');
  const [connectionModalOpen, setConnectionModalOpen] = useState(false);
  const [isEditingConnection, setIsEditingConnection] = useState(false);
  const [currentConnection, setCurrentConnection] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const [tabValue, setTabValue] = useState(0);

  const makeRequest = useAuthorizedHttp(); // Custom hook for making API calls

  // Fetch connections when component mounts
  useEffect(() => {
    const fetchConnections = async () => {
      setLoading(true);
      try {
        // Replace the following with actual API call
        /*
        const data = await makeRequest({
          url: `${process.env.REACT_APP_VORTX_URL}/connection/list`,
          method: 'GET',
        });
        */
        // Simulated API data
        setTimeout(() => {
          const data = [
            {
              id: 1,
              name: 'PostgreSQL Database',
              type: 'Database',
              status: 'Active',
              details: 'Host: db.example.com',
              host: 'db.example.com',
              port: '5432',
              username: 'admin',
              password: 'password',
            },
            {
              id: 2,
              name: 'AWS S3 Bucket',
              type: 'Cloud Storage',
              status: 'Active',
              details: 'Bucket: my-bucket',
              bucketName: 'my-bucket',
              accessKey: 'AKIA...',
              secretKey: 'SECRET...',
            },
            {
              id: 3,
              name: 'Slack Webhook',
              type: 'Webhook',
              status: 'Inactive',
              details: 'URL: https://hooks.slack.com/...',
              webhookUrl: 'https://hooks.slack.com/...',
            },
            {
              id: 4,
              name: 'Public API',
              type: 'API',
              status: 'Active',
              details: 'Endpoint: https://api.example.com',
              apiUrl: 'https://api.example.com',
              apiKey: 'APIKEY123',
            },
            {
              id: 5,
              name: 'Google Cloud Bucket',
              type: 'Cloud Bucket',
              status: 'Active',
              details: 'Bucket: gcp-bucket',
              bucketName: 'gcp-bucket',
              credentials: 'service-account.json',
            },
          ];
          setConnections(data);
          setFilteredConnections(data);
          setLoading(false);
        }, 1000);
      } catch (error) {
        setError('Failed to load connections. Please try again.');
        setConnections([]);
        setFilteredConnections([]);
        setLoading(false);
      }
    };
    fetchConnections();
  }, [makeRequest]);

  useEffect(() => {
    let filtered = [...connections];
    if (connectionSearchQuery.trim() !== '') {
      filtered = filtered.filter((conn) =>
        conn.name.toLowerCase().includes(connectionSearchQuery.toLowerCase())
      );
    }
    setFilteredConnections(filtered);
  }, [connectionSearchQuery, connections]);

  const handleAddConnection = () => {
    setIsEditingConnection(false);
    setCurrentConnection({
      name: '',
      type: '',
      status: 'Active',
      details: '',
    });
    setConnectionModalOpen(true);
  };

  const handleEditConnection = (connection) => {
    setIsEditingConnection(true);
    setCurrentConnection(connection);
    setConnectionModalOpen(true);
  };

  const handleSaveConnection = async (e) => {
    e.preventDefault();
    if (isEditingConnection) {
      // Update existing connection
      try {
        // Replace with actual API call
        /*
        await makeRequest({
          url: `${process.env.REACT_APP_VORTX_URL}/connection/update/${currentConnection.id}`,
          method: 'PUT',
          data: currentConnection,
        });
        */
        setConnections((prevConnections) =>
          prevConnections.map((conn) =>
            conn.id === currentConnection.id ? currentConnection : conn
          )
        );
        setFilteredConnections((prevConnections) =>
          prevConnections.map((conn) =>
            conn.id === currentConnection.id ? currentConnection : conn
          )
        );
        setSuccessMessage('Connection updated successfully.');
        setError(null);
      } catch (error) {
        setError('Failed to update connection. Please try again.');
        setSuccessMessage(null);
      }
    } else {
      // Create new connection
      try {
        // Replace with actual API call
        /*
        const data = await makeRequest({
          url: `${process.env.REACT_APP_VORTX_URL}/connection/create`,
          method: 'POST',
          data: currentConnection,
        });
        */
        const newConnection = {
          id: connections.length + 1,
          ...currentConnection,
        };
        setConnections((prevConnections) => [...prevConnections, newConnection]);
        setFilteredConnections((prevConnections) => [...prevConnections, newConnection]);
        setSuccessMessage('Connection created successfully.');
        setError(null);
      } catch (error) {
        setError('Failed to create connection. Please try again.');
        setSuccessMessage(null);
      }
    }
    setConnectionModalOpen(false);
  };

  const handleDeleteConnection = (connectionId) => {
    if (
      window.confirm(
        'Are you sure you want to delete this connection? This action cannot be undone.'
      )
    ) {
      try {
        // Replace with actual API call
        /*
        await makeRequest({
          url: `${process.env.REACT_APP_VORTX_URL}/connection/delete/${connectionId}`,
          method: 'DELETE',
        });
        */
        setConnections((prevConnections) =>
          prevConnections.filter((conn) => conn.id !== connectionId)
        );
        setFilteredConnections((prevConnections) =>
          prevConnections.filter((conn) => conn.id !== connectionId)
        );
        setSuccessMessage('Connection deleted successfully.');
        setError(null);
      } catch (error) {
        setError('Failed to delete connection. Please try again.');
        setSuccessMessage(null);
      }
    }
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (error) {
      setSnackbarMessage(error);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (successMessage) {
      setSnackbarMessage(successMessage);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  }, [error, successMessage]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setError(null);
    setSuccessMessage(null);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getFilteredConnectionsByTab = () => {
    let filteredByTab = [...filteredConnections];
    switch (tabValue) {
      case 1:
        filteredByTab = filteredByTab.filter((conn) => conn.type === 'Database');
        break;
      case 2:
        filteredByTab = filteredByTab.filter((conn) => conn.type === 'Cloud Storage');
        break;
      case 3:
        filteredByTab = filteredByTab.filter((conn) => conn.type === 'Webhook');
        break;
      case 4:
        filteredByTab = filteredByTab.filter((conn) => conn.type === 'API');
        break;
      case 5:
        filteredByTab = filteredByTab.filter((conn) => conn.type === 'Cloud Bucket');
        break;
      default:
        // All connections
        break;
    }
    return filteredByTab.map((conn) => ({
      id: conn.id,
      ...conn,
    }));
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const type = connectionTypes.find((t) => t.value === params.value);
        return (
          <Box display="flex" alignItems="center">
            {type?.icon}
            <Typography sx={{ ml: 1 }}>{params.value}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const status = statusOptions.find((s) => s.value === params.value);
        return (
          <Box display="flex" alignItems="center">
            {status?.icon}
            <Typography sx={{ ml: 1 }}>{params.value}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'details',
      headerName: 'Details',
      flex: 2,
      minWidth: 200,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <IconButton
              color="primary"
              size="small"
              onClick={() => handleEditConnection(params.row)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              color="error"
              size="small"
              onClick={() => handleDeleteConnection(params.row.id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <StyledCard>
        <Typography variant="h4" gutterBottom>
          Connections
        </Typography>
        <Typography variant="body1" gutterBottom>
          Manage your connections to databases, cloud buckets, webhooks, APIs, and more.
        </Typography>

        {/* Search and Add Connection Section */}
        <Grid container spacing={2} sx={{ mb: 2, mt: 2 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search connections..."
              value={connectionSearchQuery}
              onChange={(e) => setConnectionSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <IconButton edge="start">
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddConnection}
            >
              Add Connection
            </Button>
          </Grid>
        </Grid>

        {/* Tabs for Connection Types */}
        <AppBar position="static" color="default" sx={{ mt: 2 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Connection Tabs"
          >
            <Tab label="All" />
            <Tab label="Databases" icon={<StorageIcon />} />
            <Tab label="Cloud Storage" icon={<CloudUploadIcon />} />
            <Tab label="Webhooks" icon={<HttpIcon />} />
            <Tab label="APIs" icon={<CodeIcon />} />
            <Tab label="Cloud Buckets" icon={<CloudIcon />} />
          </Tabs>
        </AppBar>

        {/* Connections DataGrid */}
        {loading ? (
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <CircularProgress />
            <Typography variant="body1" sx={{ mt: 2 }}>
              Loading connections...
            </Typography>
          </Box>
        ) : (
          <div style={{ height: 500, width: '100%', marginTop: '16px' }}>
            <DataGrid
              rows={getFilteredConnectionsByTab()}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5, 10, 25]}
              sx={{
                '& .MuiDataGrid-row:hover': {
                  backgroundColor: '#f5f5f5',
                },
              }}
            />
          </div>
        )}
      </StyledCard>

      {/* Add/Edit Connection Dialog */}
      <Dialog
        open={connectionModalOpen}
        onClose={() => setConnectionModalOpen(false)}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Slide}
      >
        <DialogTitle>
          {isEditingConnection ? 'Edit Connection' : 'Add Connection'}
        </DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleSaveConnection}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Connection Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={currentConnection ? currentConnection.name : ''}
                  onChange={(e) =>
                    setCurrentConnection({
                      ...currentConnection,
                      name: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="connection-type-label">Connection Type</InputLabel>
                  <Select
                    labelId="connection-type-label"
                    label="Connection Type"
                    value={currentConnection ? currentConnection.type : ''}
                    onChange={(e) =>
                      setCurrentConnection({
                        ...currentConnection,
                        type: e.target.value,
                      })
                    }
                    required
                  >
                    {connectionTypes.map((option) => (
                      <MenuItem value={option.value} key={option.value}>
                        <Box display="flex" alignItems="center">
                          {option.icon}
                          <Typography sx={{ ml: 1 }}>{option.label}</Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Conditional Fields Based on Connection Type */}
              {currentConnection && currentConnection.type === 'Database' && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      label="Host"
                      variant="outlined"
                      fullWidth
                      required
                      value={currentConnection.host || ''}
                      onChange={(e) =>
                        setCurrentConnection({
                          ...currentConnection,
                          host: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Port"
                      variant="outlined"
                      fullWidth
                      required
                      value={currentConnection.port || ''}
                      onChange={(e) =>
                        setCurrentConnection({
                          ...currentConnection,
                          port: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Username"
                      variant="outlined"
                      fullWidth
                      required
                      value={currentConnection.username || ''}
                      onChange={(e) =>
                        setCurrentConnection({
                          ...currentConnection,
                          username: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Password"
                      variant="outlined"
                      fullWidth
                      type="password"
                      required
                      value={currentConnection.password || ''}
                      onChange={(e) =>
                        setCurrentConnection({
                          ...currentConnection,
                          password: e.target.value,
                        })
                      }
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={() =>
                              setCurrentConnection({
                                ...currentConnection,
                                showPassword: !currentConnection.showPassword,
                              })
                            }
                          >
                            {currentConnection.showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                </>
              )}

              {currentConnection && currentConnection.type === 'Cloud Storage' && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      label="Bucket Name"
                      variant="outlined"
                      fullWidth
                      required
                      value={currentConnection.bucketName || ''}
                      onChange={(e) =>
                        setCurrentConnection({
                          ...currentConnection,
                          bucketName: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Access Key"
                      variant="outlined"
                      fullWidth
                      required
                      value={currentConnection.accessKey || ''}
                      onChange={(e) =>
                        setCurrentConnection({
                          ...currentConnection,
                          accessKey: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Secret Key"
                      variant="outlined"
                      fullWidth
                      type="password"
                      required
                      value={currentConnection.secretKey || ''}
                      onChange={(e) =>
                        setCurrentConnection({
                          ...currentConnection,
                          secretKey: e.target.value,
                        })
                      }
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            aria-label="toggle secret key visibility"
                            edge="end"
                            onClick={() =>
                              setCurrentConnection({
                                ...currentConnection,
                                showSecretKey: !currentConnection.showSecretKey,
                              })
                            }
                          >
                            {currentConnection.showSecretKey ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                </>
              )}

              {currentConnection && currentConnection.type === 'Webhook' && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      label="Webhook URL"
                      variant="outlined"
                      fullWidth
                      required
                      value={currentConnection.webhookUrl || ''}
                      onChange={(e) =>
                        setCurrentConnection({
                          ...currentConnection,
                          webhookUrl: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </>
              )}

              {currentConnection && currentConnection.type === 'API' && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      label="API URL"
                      variant="outlined"
                      fullWidth
                      required
                      value={currentConnection.apiUrl || ''}
                      onChange={(e) =>
                        setCurrentConnection({
                          ...currentConnection,
                          apiUrl: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="API Key"
                      variant="outlined"
                      fullWidth
                      required
                      value={currentConnection.apiKey || ''}
                      onChange={(e) =>
                        setCurrentConnection({
                          ...currentConnection,
                          apiKey: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </>
              )}

              {currentConnection && currentConnection.type === 'Cloud Bucket' && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      label="Bucket Name"
                      variant="outlined"
                      fullWidth
                      required
                      value={currentConnection.bucketName || ''}
                      onChange={(e) =>
                        setCurrentConnection({
                          ...currentConnection,
                          bucketName: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Credentials"
                      variant="outlined"
                      fullWidth
                      required
                      value={currentConnection.credentials || ''}
                      onChange={(e) =>
                        setCurrentConnection({
                          ...currentConnection,
                          credentials: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="connection-status-label">Status</InputLabel>
                  <Select
                    labelId="connection-status-label"
                    label="Status"
                    value={currentConnection ? currentConnection.status : ''}
                    onChange={(e) =>
                      setCurrentConnection({
                        ...currentConnection,
                        status: e.target.value,
                      })
                    }
                    required
                  >
                    {statusOptions.map((option) => (
                      <MenuItem value={option.value} key={option.value}>
                        <Box display="flex" alignItems="center">
                          {option.icon}
                          <Typography sx={{ ml: 1 }}>{option.label}</Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Details"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  value={currentConnection ? currentConnection.details : ''}
                  onChange={(e) =>
                    setCurrentConnection({
                      ...currentConnection,
                      details: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
            <DialogActions sx={{ mt: 2 }}>
              <Button
                onClick={() => setConnectionModalOpen(false)}
                color="secondary"
                startIcon={<CloseIcon />}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<SaveIcon />}
              >
                {isEditingConnection ? 'Save Changes' : 'Add Connection'}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Snackbar for Feedback Messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        ContentProps={{
          sx: {
            backgroundColor: snackbarSeverity === 'success' ? 'green' : 'red',
          },
        }}
      />
    </Box>
  );
};

export default ConnectionsTab;
