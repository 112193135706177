import React, { useState, useEffect } from "react";
import { appActions } from "../context/app-slice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuthorizedHttp from "../hooks/use-authorized-http";
import EastIcon from "@mui/icons-material/East";
import MetamaskIcon from "@mui/icons-material/AccountBalanceWallet";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {
  CircularProgress,
  Typography,
  Button,
  TextField,
  Box,
  Container,
  Grid,
  Paper,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import CryptoJS from "crypto-js";
import "../css/Login.css";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const makeRequest = useAuthorizedHttp();
  const appCtx = useSelector((state) => state.app);
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // State Variables
  const [userData, setUserData] = useState({
    email: appCtx.userDetails?.email || "",
    password: "",
  });
  const [clickedOnLoginOtp, setClickedOnLoginOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [reSendOtp, setReSendOtp] = useState(false);
  const [isVerifiedOtp, setIsVerifiedOtp] = useState(false);
  const [loader, setLoader] = useState(false);
  const [nonCustodialLoader, setNonCustodialLoader] = useState(false);
  const [metamaskClicked, setMetamaskClicked] = useState(false);
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isMetaMaskRequesting, setIsMetaMaskRequesting] = useState(false);

  const [showDetailsForm, setShowDetailsForm] = useState(false);
  const [additionalDetails, setAdditionalDetails] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
  });

  // Timer state
  const [timer, setTimer] = useState(300); // 5 minutes in seconds
  const [resendTimer, setResendTimer] = useState(300); // 5 minutes in seconds

  // Typing words for rotating effect
  const words = ["Images", "Audios", "Transactions", "IoTs", "Locations", "Texts"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [displayedWord, setDisplayedWord] = useState("");
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    let typingTimeout;
    let erasingTimeout;
    let nextWordTimeout;

    if (isTyping) {
      if (displayedWord.length < words[currentWordIndex].length) {
        typingTimeout = setTimeout(() => {
          setDisplayedWord(words[currentWordIndex].substring(0, displayedWord.length + 1));
        }, 100);
      } else {
        nextWordTimeout = setTimeout(() => {
          setIsTyping(false);
        }, 1500);
      }
    } else {
      if (displayedWord.length > 0) {
        erasingTimeout = setTimeout(() => {
          setDisplayedWord(words[currentWordIndex].substring(0, displayedWord.length - 1));
        }, 50);
      } else {
        setIsTyping(true);
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      }
    }

    return () => {
      clearTimeout(typingTimeout);
      clearTimeout(erasingTimeout);
      clearTimeout(nextWordTimeout);
    };
  }, [displayedWord, isTyping, words, currentWordIndex]);

  // Timer handling for OTP
  useEffect(() => {
    let interval = null;
    if (clickedOnLoginOtp && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [clickedOnLoginOtp, timer]);

  useEffect(() => {
    let interval = null;
    if (reSendOtp && resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [reSendOtp, resendTimer]);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  // Handle input changes for login form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevValue) => ({ ...prevValue, [name]: value }));
  };

  // Handle input changes for additional details form
  const handleDetailsChange = (e) => {
    const { name, value } = e.target;
    setAdditionalDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  // Send OTP
  const handleSendOtp = () => {
    const email = userData.email?.trim();

    if (email.length !== 0) {
      const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (emailRegex.test(email)) {
        setLoader(true);
        makeRequest(
          {
            url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/otp/send?type=login`,
            data: { email },
            method: "post",
          },
          (data) => {
            toast.success("OTP Sent Successfully!");
            setClickedOnLoginOtp(true);
            setLoader(false);
            if (data.message === "Success" && data.status === true) {
              setTimer(300); // Reset timer to 5 minutes
            }
          },
          (error) => {
            toast.error("Please try again later.");
            setLoader(false);
          }
        );
      } else {
        toast.error("Email ID is incorrect!");
      }
    } else {
      toast.error("Email ID cannot be empty!");
    }
  };

  // Verify OTP
  const handleCheckOtp = async () => {
    setLoader(true);
    if (!appCtx.isLoggedIn) {
      makeRequest(
        {
          url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/otp/verify?type=login`,
          data: {
            email: userData.email,
            otp: otp,
            referredBy: localStorage.getItem("referral"),
          },
          method: "post",
        },
        (data) => {
          setIsVerifiedOtp(true);
          setLoader(false);
          dispatch(appActions.updateUserDetails(data));
          dispatch(appActions.updateAuthToken(data?.authToken));
          window.localStorage.setItem("userId", data.userId);
          window.localStorage.setItem("authToken", data.authToken);
          window.localStorage.setItem("firstName", data.firstName);
          window.localStorage.setItem("lastName", data.lastName);

          // Role Assignment Logic
          if (data?.role) {
            dispatch(appActions.setRole(data?.role));
          } else {
            dispatch(appActions.setRole("user"));
          }

          if (!data.firstName) {
            // Show the additional details form for OTP login
            setShowDetailsForm(true);
          } else {
            dispatch(appActions.login(true));
            navigate("/");
          }
        },
        (error) => {
          toast.error("OTP entered is incorrect!");
          setLoader(false);
        }
      );
    }
  };

  // Resend OTP
  const handleResendOtp = () => {
    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/otp/send?type=login&resend=true`,
        data: { email: userData.email },
        method: "post",
      },
      (data) => {
        toast.success("OTP Resent Successfully!");
        setReSendOtp(true);
        setOtp("");
        setResendTimer(300); // Reset resend timer to 5 minutes
        if (data.message === "Success" && data.status === true) {
          setClickedOnLoginOtp(true);
          setLoader(false);
          setTimer(300); // Reset main timer
        }
      },
      (error) => {
        toast.error("Failed to resend OTP. Please try again.");
      }
    );
  };

  // Handle MetaMask Sign-in
  const handleMetamaskSignin = async () => {
    if (isMetaMaskRequesting) {
      toast.warn(
        "MetaMask request is already in progress. Please complete the current request."
      );
      return;
    }

    if (!window.ethereum) {
      alert("MetaMask not detected");
      return;
    }

    setIsMetaMaskRequesting(true);
    setMetamaskClicked(true);

    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      await handleNonCustodialSignin(accounts[0]);
    } catch (error) {
      if (error.code === -32002) {
        toast.error(
          "A MetaMask request is already pending. Please check your MetaMask extension."
        );
      } else {
        console.error(error);
        toast.error("An error occurred while connecting to MetaMask.");
      }
    } finally {
      setIsMetaMaskRequesting(false);
      setMetamaskClicked(false);
    }
  };

  // Handle Non-Custodial Sign-in
  const handleNonCustodialSignin = async (walletAddress) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
        },
      };

      if (appCtx.authToken != null) {
        config.headers["X-Auth-Token"] = appCtx.authToken;
      }

      setNonCustodialLoader(true); // Show loading spinner

      // Use "ETHEREUM" instead of "BSC"
      const blockchain = "ETHEREUM";
      const wallet = "TALEWALLET";
      const product = "TALE_WALLET";

      const response = await axios.post(
        `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/blockchain/crypto/account/login`,
        {
          blockchain,
          blockchainAddress: walletAddress,
          marketplaceAddress: "0",
          wallet,
          product,
        },
        config
      );

      const message = response.data.secretKey;

      const keyEncrypt = CryptoJS.SHA256(
        JSON.stringify(response.data.secretKey)
      ).toString();
      dispatch(appActions.setEvmEncrypt(keyEncrypt));

      const signature = await window.ethereum.request({
        method: "personal_sign",
        params: [message, walletAddress],
      });

      const verifyResponse = await axios.post(
        `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/blockchain/crypto/wallet/verify`,
        {
          blockchain,
          blockchainAddress: walletAddress,
          marketplaceAddress: "0", // Ensure this is included
          signedSecretKey: signature,
          userId: response.data.userId,
        },
        config
      );

      // Role Assignment Logic
      if (verifyResponse.data.role) {
        dispatch(appActions.setRole(verifyResponse.data.role));
      } else {
        dispatch(appActions.setRole("user"));
      }

      dispatch(appActions.updateAuthToken(verifyResponse.data.authToken));
      dispatch(
        appActions.updateUserDetails({
          ...appCtx.userDetails,
          userId: verifyResponse.data.userId,
          firstName: verifyResponse.data.firstName,
          lastName: verifyResponse.data.lastName,
        })
      );

      window.localStorage.setItem("userId", verifyResponse.data.userId);
      window.localStorage.setItem("authToken", verifyResponse.data.authToken);
      window.localStorage.setItem("firstName", verifyResponse.data.firstName);
      window.localStorage.setItem("lastName", verifyResponse.data.lastName);

      dispatch(appActions.setBlockChain(blockchain));
      dispatch(appActions.setCustodial(false));

      dispatch(appActions.login(true));
      toast.success("Wallet connected successfully");
      navigate("/");
    } catch (error) {
      console.error("Error connecting...", error);
      if (error.response) {
        console.error("API error response:", error.response.data);
        toast.error(
          `Error: ${
            error.response.data.message || "Failed to connect with MetaMask."
          }`
        );
      } else {
        toast.error("Failed to connect with MetaMask.");
      }
    } finally {
      setNonCustodialLoader(false); // Hide loading spinner
    }
  };

  // Handle submission of additional details
  const handleSubmitDetails = () => {
    // Validate input if needed
    if (!additionalDetails.firstName || !additionalDetails.lastName) {
      toast.error("First Name and Last Name are required");
      return;
    }

    setLoader(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/user/profile/update`,
        data: {
          firstName: additionalDetails.firstName,
          lastName: additionalDetails.lastName,
          mobile: additionalDetails.mobileNumber,
          userId: appCtx.userDetails.userId, // Ensure userId is included
        },
        method: "PUT",
        headers: {
          "X-Auth-Token": appCtx.authToken, // Added X-Auth-Token header
        },
      },
      (data) => {
        // Update user details in context and local storage
        dispatch(appActions.updateUserDetails(data));
        window.localStorage.setItem("firstName", data.firstName);
        window.localStorage.setItem("lastName", data.lastName);
        window.localStorage.setItem("mobileNumber", data.mobile);

        setLoader(false);
        dispatch(appActions.login(true));
        navigate("/");
      },
      (error) => {
        toast.error("Failed to update details");
        setLoader(false);
      }
    );
  };

  // Format time in mm:ss
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <Box
      className="container-fluid"
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "stretch", // Ensure children stretch to full height
        padding: 0,
        backgroundColor: "white",
      }}
    >
      <Grid container sx={{ height: "100%" }}>
        {/* Left section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            backgroundColor: "background.paper",
            padding: isMobile ? 3 : 8,
            position: "relative",
            boxSizing: "border-box",
            height: "100vh",
          }}
        >
          {/* Logo Text on Top Left */}
          <Box
  sx={{
    position: "absolute",
    top: 20,
    left: 20,
    fontFamily: "Montserrat, sans-serif",
    fontSize: "24px",
    fontWeight: "bold",
    color: "rgb(25, 118, 210)",
  }}
>
  <a
    href="https://vortx.ai"
    target="_blank" // Opens in a new tab
    rel="noopener noreferrer" // For security reasons
    style={{ textDecoration: "none", color: "rgb(25, 118, 210)" }} // Styling to remove underline
  >
    Vortx
  </a>
</Box>


          {/* Content Container */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h3" sx={{ color: "primary.main", mb: 4 }}>
              SYNTHETIC SATELLITE IMAGING
            </Typography>
            <Typography variant="body1" sx={{ fontSize: isMobile ? "16px" : "22px" }}>
               from{" "}
              <Box
                component="span"
                sx={{
                  color: "primary.main",
                  fontWeight: "bold",
                  position: "relative",
                }}
              >
                {displayedWord}
                <Box
                  component="span"
                  sx={{
                    borderRight: "2px solid",
                    marginLeft: "5px",
                    animation: "blink-caret 0.8s infinite",
                    position: "absolute",
                    top: 0,
                    right: -7,
                    height: "100%",
                  }}
                ></Box>
              </Box>
            </Typography>
          </Box>

          {/* Bottom left: LinkedIn link */}
          <Box
  sx={{
    position: "absolute",
    bottom: 20,
    left: 20,
    display: { xs: "none", md: "flex" },
    alignItems: "center",
  }}
>
  <a
    href="https://www.linkedin.com/company/vortxai/"
    target="_blank" // Opens in a new tab
    rel="noopener noreferrer" // For security reasons
    style={{ display: "flex", alignItems: "center", textDecoration: "none", color: "black" }}
  >
    <LinkedInIcon style={{ marginRight: 10 }} />
    LinkedIn
  </a>
</Box>


          {/* Bottom right: Copyright */}
          <Box
            sx={{
              position: "absolute",
              bottom: 20,
              right: 20,
              color: "black",
              display: { xs: "none", md: "block" },
            }}
          >
            &copy; 2024 Vortx AI Private Limited
          </Box>
        </Grid>

        {/* Right section */}
        <Grid item xs={12} md={6} sx={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Box
            sx={{
              backgroundColor: "background.default",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: isMobile ? 3 : 8,
              boxSizing: "border-box",
            }}
          >
            <Container maxWidth="sm">
              <Paper elevation={3} sx={{ p: isMobile ? 2 : 4, width: "100%" }}>
                {showDetailsForm ? (
                  <Box component="form" noValidate>
                    <Typography variant="h6" gutterBottom>
                      Complete Your Profile
                    </Typography>
                    <TextField
                      label="First Name"
                      name="firstName"
                      value={additionalDetails.firstName}
                      onChange={handleDetailsChange}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Last Name"
                      name="lastName"
                      value={additionalDetails.lastName}
                      onChange={handleDetailsChange}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Mobile Number"
                      name="mobileNumber"
                      value={additionalDetails.mobileNumber}
                      onChange={handleDetailsChange}
                      fullWidth
                      margin="normal"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmitDetails}
                      fullWidth
                      disabled={loader}
                      endIcon={
                        loader ? <CircularProgress size={20} color="inherit" /> : null
                      }
                    >
                      Submit
                    </Button>
                  </Box>
                ) : (
                  <Box component="form" noValidate>
                    <Typography variant="h6" gutterBottom>
                      Login with your enterprise mail.
                    </Typography>
                    {!clickedOnLoginOtp ? (
                      <TextField
                        label="Email address"
                        name="email"
                        value={userData.email}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                      />
                    ) : (
                      <TextField
                        label="OTP"
                        type={isPasswordVisible ? "text" : "password"}
                        name="otp"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        fullWidth
                        margin="normal"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={togglePasswordVisibility} edge="end">
                                <FontAwesomeIcon
                                  icon={isPasswordVisible ? faEye : faEyeSlash}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={!clickedOnLoginOtp ? handleSendOtp : handleCheckOtp}
                      fullWidth
                      disabled={loader}
                      endIcon={
                        loader ? <CircularProgress size={20} color="inherit" /> : <EastIcon />
                      }
                      sx={{ mt: 3 }}
                    >
                      {!clickedOnLoginOtp ? `Send OTP` : "Verify OTP"}
                    </Button>

                    {clickedOnLoginOtp && (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ mt: 3 }}
                      >
                        <Typography
                          className={`fw-bold ${
                            reSendOtp ? "textSecondary" : "textPrimary"
                          }`}
                          sx={{ cursor: reSendOtp ? "not-allowed" : "pointer" }}
                          onClick={reSendOtp ? null : handleResendOtp}
                        >
                          Resend OTP
                        </Typography>
                        <Typography className="fw-bold">
                          {timer > 0 ? formatTime(timer) : "00:00"}
                        </Typography>
                      </Box>
                    )}

                    <Box textAlign="center" sx={{ mt: 3 }}>
                      <Typography variant="body2">Or continue anonymously with</Typography>
                      <Button
                        onClick={handleMetamaskSignin}
                        variant="outlined"
                        color="secondary"
                        startIcon={<MetamaskIcon />}
                        fullWidth
                        disabled={isMetaMaskRequesting}
                        sx={{ mt: 2 }}
                      >
                        MetaMask
                        {nonCustodialLoader && metamaskClicked && (
                          <CircularProgress size={20} color="inherit" sx={{ ml: 2 }} />
                        )}
                      </Button>
                    </Box>
                  </Box>
                )}
              </Paper>
            </Container>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
