import React, { useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { toast } from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { CircularProgress } from "@mui/material";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";

const SupportModal = ({ openModal, setOpenModal }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const makeRequest = useAuthorizedHttp();
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  const [userId, setUserId] = useState("");
  const [address, setAddress] = useState("");
  const [isQueryEmailSent, setIsQueryEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const sendEmail = () => {
    if (email && query && address) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        setLoading(true);
        makeRequest(
          {
            url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/internal/email/send`,
            data: {
              body: `${email} ${userId} ${address} ${query}`,
              email: "info@onnftverse.com",
              subject: "NFTVerse Info query Details",
            },
            method: "post",
            headers: {
              "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
              "Content-Type": "application/json",
            },
          },
          (data) => {
            setIsQueryEmailSent(true);
            setEmail("");
            setQuery("");
            setUserId("");
            setAddress("");
            toast("Your Query Mailed successfully!");
            setLoading(false);
          },
          (error) => {
            toast.error("Something went worng");
            setLoading(false);
          }
        );
      } else {
        toast.error("Incorrect Email Id");
      }
    } else {
      toast.error("Input field cannot be empty");
    }
  };

  const width = window.screen.width;
  let isMobileDevice = width < 600;
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: 9999,
      backgroundColor: "#FFFFFF",

      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      border: `${appCtx?.isDarkMode ? "#FFFFFF" : "#FFFFFF"}`,
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
      width: `${isMobileDevice ? "95%" : "500px"}`,
    },
  };
  function closeModal() {
    setOpenModal(false);
  }

  // new code

  const [selectedSupport, setSelectedSupport] = useState("");
  const [selectedPriority, setSelectedPriority] = useState("");
  const [formData, setFormData] = useState({
    project: "",
    callback: "",
    contactMail: "",
    description: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("supportType", selectedSupport);
    data.append("priority", selectedPriority);
    data.append("project", formData.project);
    data.append("callback", formData.callback);
    data.append("contactMail", formData.contactMail);
    data.append("description", formData.description);

    // fetch("https://jsonplaceholder.typicode.com/posts", {
    //   method: "POST",
    //   body: data,
    // })
    //   .then((response) => response.json())
    //   .then((json) => console.log(json));
  };

  return (
    <Modal
      isOpen={openModal}
      // onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="w-100  position-relative">
        <div className="d-flex justify-content-end  position-relative">
          <button
            onClick={closeModal}
            className="btn btn-link position-absolute top-1 right-2"
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
        <Form onSubmit={handleSubmit} className="p-4 border rounded">
          <h3 className="mb-3 text-center">Vortx support system</h3>

          <Row className="mb-3">
            <Form.Label>Select Support Type</Form.Label>
            <ButtonGroup>
              {["Help", "Bugs", "Compliances", "FAQs"].map((type, index) => (
                <ToggleButton
                  key={type}
                  type="radio"
                  variant={
                    selectedSupport === type ? "primary" : "outline-primary"
                  }
                  name="support"
                  value={type}
                  checked={selectedSupport === type}
                  onClick={() => setSelectedSupport(type)}
                  className={`${index === 0 ? "rounded-start" : ""} ${
                    index === 3 ? "rounded-end" : ""
                  }`}
                >
                  {type}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </Row>

          <Row className="mb-3">
            <Form.Label>Select Priority</Form.Label>
            <ButtonGroup className="w-100">
              {["Urgent", "Normal", "Lite"].map((priority, index) => (
                <ToggleButton
                  key={priority}
                  type="radio"
                  variant={
                    selectedPriority === priority
                      ? "primary"
                      : "outline-primary"
                  }
                  name="priority"
                  value={priority}
                  checked={selectedPriority === priority}
                  onClick={() => setSelectedPriority(priority)}
                  className={`flex-grow-1 ${
                    index === 0 ? "rounded-start" : ""
                  } ${index === 2 ? "rounded-end" : ""}`}
                >
                  {priority}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </Row>

          <Row className="mb-3">
            <Form.Group controlId="selectProject">
              <Form.Label>Select Project</Form.Label>
              <Form.Control
                as="select"
                name="project"
                value={formData.project}
                onChange={handleChange}
              >
                <option>Project 1</option>
                <option>Project 2</option>
                <option>Project 3</option>
              </Form.Control>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group controlId="callback">
              <Form.Label>Request Callback</Form.Label>
              <Form.Control
                type="text"
                name="callback"
                value={formData.callback}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group controlId="contactMail">
              <Form.Label>Contact on Mail</Form.Label>
              <Form.Control
                type="email"
                name="contactMail"
                value={formData.contactMail}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group controlId="description">
              <Form.Label>Explain Please</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                placeholder="Please describe"
                value={formData.description}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Button type="submit" className="me-3">
              Send
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default SupportModal;
